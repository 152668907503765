export function isEmailValid(email: string) {
  return (
    null !=
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  );
}

export function isPasswordValid(password: string) {
  return (
    null != password.match(/^((?!.*[\s])(?=.*[a-z])(?=.*[A-Z]).{10,})/)
  );
}

export function isVerificationCodeValid(verificationCode: string) {
  return verificationCode.length > 0;
}
