import React, { useContext } from "react";
import { IonPage } from "@ionic/react";
import PageHeader from "components/PageHeader";
import Messages from "./Messages";
import { MessagesContext } from "providers/Messages/MessagesAppState";
import { Conversation } from "providers/Messages/MessagesActionTypes";
import { useParams } from "react-router";

const MessagesPage = () => {
  const { state } = useContext(MessagesContext);
  const { conversationId } = useParams();

  function getConversation(): Conversation | undefined {
    return state.conversations?.find((conversation: Conversation) => {
      return conversation.id.toString() === conversationId;
    });
  }

  function getTitle(): string {
    return getConversation()?.location || "";
  }

  return (
    <IonPage className="pageLightGrey">
      <PageHeader title={getTitle()} />
      <Messages
        conversationId={conversationId || ""}
        hasOneConversation={false}
      />
    </IonPage>
  );
};

export default MessagesPage;
