import { MainTab } from "./index";

export enum ActionType {
  SplitPaneVisibilityChanged = "SplitPaneVisibilityChanged",
  MainPageTabChanged = "MainPageTabChanged",
}

interface SplitPaneVisibilityChanged {
  type: ActionType.SplitPaneVisibilityChanged;
  isSplitPaneVisible: boolean;
}

interface MainPageTabChanged {
  type: ActionType.MainPageTabChanged;
  selectedMainTab: MainTab;
}

export type UIActionTypes = SplitPaneVisibilityChanged | MainPageTabChanged;
