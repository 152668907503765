import { LocalStorageKeys } from "./Constants";
import {
  ActionType,
  PostSigninResponse,
  PostSignupResponse,
} from "providers/User/UserActionTypes";
import { UserSession } from "service/UserService";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

export class SessionUtils {
  static initSession(): Promise<UserSession> {
    return new Promise(async (resolve, reject) => {
      const existingSession = SessionUtils.getSession();
      if (existingSession) {
        const email = existingSession.email;
        const response = {
          UserPoolId: existingSession.UserPoolId,
          ClientId: existingSession.ClientId,
          AccessToken: existingSession.AccessToken,
          IdToken: existingSession.IdToken,
          RefreshToken: existingSession.RefreshToken,
        };

        const AccessToken = new CognitoAccessToken(response);
        const IdToken = new CognitoIdToken(response);
        const RefreshToken = new CognitoRefreshToken(response);
        const sessionData = {
          IdToken,
          AccessToken,
          RefreshToken,
        };
        const userSession = new CognitoUserSession(sessionData);
        const userPool = new CognitoUserPool({
          UserPoolId: response.UserPoolId,
          ClientId: response.ClientId,
        });
        const userData = {
          Username: email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.setSignInUserSession(userSession);

        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            if (this.isSessionExpired(session)) {
              cognitoUser.refreshSession(
                RefreshToken,
                (err: any, session: CognitoUserSession) => {
                  if (err) {
                    reject(err);
                  } else {
                    this.onCognitoUserSession(
                      existingSession,
                      session,
                      resolve
                    );
                  }
                }
              );
            } else {
              this.onCognitoUserSession(existingSession, session, resolve);
            }
          }
        });
      } else {
        reject(null);
      }
    });
  }

  static getSession(): UserSession | null {
    const email = localStorage.getItem(LocalStorageKeys.email);
    const IdToken = localStorage.getItem(LocalStorageKeys.IdToken);
    const RefreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
    const AccessToken = localStorage.getItem(LocalStorageKeys.AccessToken);
    const ClientId = localStorage.getItem(LocalStorageKeys.ClientId);
    const UserPoolId = localStorage.getItem(LocalStorageKeys.UserPoolId);
    if (
      email &&
      IdToken &&
      RefreshToken &&
      AccessToken &&
      ClientId &&
      UserPoolId
    ) {
      return {
        email,
        IdToken,
        RefreshToken,
        AccessToken,
        ClientId,
        UserPoolId,
      };
    }
    return null;
  }

  static storeSession(
    email: string,
    response: PostSignupResponse | PostSigninResponse
  ) {
    localStorage.setItem(LocalStorageKeys.email, email);
    localStorage.setItem(LocalStorageKeys.IdToken, response.IdToken);
    localStorage.setItem(LocalStorageKeys.RefreshToken, response.RefreshToken);
    localStorage.setItem(LocalStorageKeys.AccessToken, response.AccessToken);
    localStorage.setItem(LocalStorageKeys.ClientId, response.ClientId);
    localStorage.setItem(LocalStorageKeys.UserPoolId, response.UserPoolId);
  }

  static clearSession(signOutCognitoUser: boolean = false) {
    if (signOutCognitoUser) {
      this.getCognitoUser()?.signOut();
    }
    localStorage.clear();
  }

  /*
    PRIVATE
     */
  private static isSessionExpired(session: CognitoUserSession): boolean {
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    return (
      session.getAccessToken().getExpiration() < secondsSinceEpoch &&
      session.getIdToken().getExpiration() < secondsSinceEpoch
    );
  }

  private static onCognitoUserSession(
    existingSession: UserSession,
    session: CognitoUserSession,
    resolve: Function
  ) {
    SessionUtils.storeSession(existingSession.email, {
      type: ActionType.PostSigninResponse,
      UserPoolId: existingSession.UserPoolId,
      ClientId: existingSession.ClientId,
      AccessToken: session.getAccessToken().getJwtToken(),
      IdToken: session.getIdToken().getJwtToken(),
      RefreshToken: session.getRefreshToken().getToken(),
    });
    resolve({
      email: existingSession.email,
      UserPoolId: existingSession.UserPoolId,
      ClientId: existingSession.ClientId,
      AccessToken: session.getAccessToken().getJwtToken(),
      IdToken: session.getIdToken().getJwtToken(),
      RefreshToken: session.getRefreshToken().getToken(),
    });
  }

  private static getCognitoUser(): CognitoUser | null {
    const UserPoolId = localStorage.getItem(LocalStorageKeys.UserPoolId);
    const ClientId = localStorage.getItem(LocalStorageKeys.UserPoolId);
    const email = localStorage.getItem(LocalStorageKeys.email);

    if (UserPoolId && ClientId && email) {
      const userPool = new CognitoUserPool({
        UserPoolId,
        ClientId,
      });
      const userData = {
        Username: email,
        Pool: userPool,
      };
      return new CognitoUser(userData);
    }
    return null;
  }
}
