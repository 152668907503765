import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonFooter,
  IonList,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import { withMessagesService, WithMessagesServiceProps } from "service";
import MessageTopic from "./MessageTopic";
import CaregiverMessageCell from "./CaregiverMessageCell";
import OwnMessageCell from "./OwnMessageCell";
import moment from "moment";
import MessageDivider from "./MessageDivider";
import { useTranslation } from "react-i18next";
import {
  Message,
  MessagesResponse,
} from "providers/Messages/MessagesActionTypes";
import "./Messages.css";
import NewMessage from "./NewMessage";
import { Formatter } from "util/Formatter";
import { RouteComponentProps, withRouter } from "react-router";
import UnreadMessagesDivider from "./UnreadMessagesDivider";
import { MessagesContext } from "providers/Messages/MessagesAppState";

interface MessagesProps extends WithMessagesServiceProps, RouteComponentProps {
  conversationId: string;
  hasOneConversation: boolean;
}

const Messages = (props: MessagesProps) => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const listBottomRef = useRef<HTMLDivElement>(null);
  const firstUnreadRef = useRef<HTMLDivElement>(null);

  const { state } = useContext(MessagesContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [response, setResponse] = useState<MessagesResponse | null>(null);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isLoadMoreEnabled, setLoadMoreEnabled] = useState(false);
  const { t } = useTranslation();

  function getContentView(): HTMLIonContentElement | null {
    return document.getElementById("messagesContent") as HTMLIonContentElement;
  }

  const scrollToBottom = (smooth: boolean = false) => {
    if (smooth) {
      getContentView()?.scrollToBottom(300);
    } else {
      getContentView()?.scrollToBottom(0);
    }
  };

  const id = props.conversationId;

  function post(func: Function) {
    // setTimeout(0) is a common web hac to "ensure" one rendering cycle
    setTimeout(() => {
      func();
    }, 0);
  }

  /*
    Ionic hooks are used when there are multiple conversations since IonicRouterOutlet
    somehow messes React hooks.
   */
  useIonViewWillEnter(() => {
    if (!props.hasOneConversation) {
      setLoading(true);
      setError(false);
      setEmpty(false);
      setResponse(null);
    }
  }, [id]);

  useIonViewDidEnter(() => {
    if (!props.hasOneConversation) {
      fetchMessages();
    }
  }, [id]);

  /*
    And on the other hand, when there is only single conversation, Ionic hooks don't work properly,
    so for that case we use React useEffect hook.
   */
  useEffect(() => {
    if (props.hasOneConversation) {
      setLoading(true);
      setError(false);
      setEmpty(false);
      setResponse(null);
      fetchMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function fetchMessages() {
    if (id) {
      props.messagesService
        .fetchMessages(id)
        .then((response) => {
          setLoading(false);
          setResponse(response);
          setEmpty(response.messages.length === 0);

          post(() => {
            if (firstUnreadRef.current) {
              // @ts-ignore
              firstUnreadRef.current?.scrollIntoViewIfNeeded(true);
            } else {
              scrollToBottom();
            }
          });

          // Enable fetch more with small delay
          setTimeout(() => {
            setLoadMoreEnabled(true);
          }, 50);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }

  const fetchMore = () => {
    if (response && isLoadMoreEnabled && id) {
      setLoadingMore(true);
      setLoadMoreEnabled(false);
      props.messagesService
        .fetchMessages(id, response.nextPage)
        .then((moreResponse) => {
          const previousScrollHeight = document.getElementById("messagesList")!!
            .scrollHeight;
          contentRef.current?.getScrollElement().then((scrollElement) => {
            const previousScrollPos = scrollElement.scrollTop;
            response.messages = moreResponse.messages.concat(response.messages);
            response.nextPage = moreResponse.nextPage;
            setResponse(response);
            setLoadingMore(false);
            setLoadMoreEnabled(true);
            post(() => {
              const currentScrollHeight = document.getElementById(
                "messagesList"
              )!!.scrollHeight;
              const adjustedScrollPos =
                previousScrollPos + currentScrollHeight - previousScrollHeight;
              (document.getElementById(
                "messagesContent"
              ) as HTMLIonContentElement).scrollToPoint(0, adjustedScrollPos);
            });
          });
        })
        .catch(() => {
          setLoadingMore(false);
          setLoadMoreEnabled(true);
        });
    }
  };

  function getSection(
    message: Message,
    previousMessage: Message | null,
    response: MessagesResponse,
    firstUnread: boolean
  ) {
    if (firstUnread) {
      return <UnreadMessagesDivider unreadCount={response.unreadCount} />;
    } else {
      const date = moment(message.created).startOf("day");
      if (!previousMessage) {
        return <MessageDivider title={Formatter.getMessageSendDate(date, t)} />;
      } else {
        const title = Formatter.getMessageSendDate(date, t);
        const prevTitle = Formatter.getMessageSendDate(
          moment(previousMessage.created).startOf("day"),
          t
        );
        if (title !== prevTitle) {
          return <MessageDivider title={title} />;
        }
      }
    }
    return null;
  }

  const getContent = () => {
    if (response) {
      const unreadId = state.messages.find((message: Message) => {
        return !message.read && message.author;
      })?.id;
      return (
        <IonList
          id="messagesList"
          className="messagesList ion-padding-horizontal"
        >
          {state.messages.map((message: Message, index: number) => {
            const prevMessage = state.messages[index - 1];
            const firstUnread = message.id === unreadId;
            let MessageCell;
            if (message.author) {
              MessageCell = (
                <CaregiverMessageCell customerId={id} message={message} />
              );
            } else {
              MessageCell = (
                <OwnMessageCell customerId={id} message={message} />
              );
            }
            return (
              <div key={message.id} ref={firstUnread ? firstUnreadRef : null}>
                {getSection(message, prevMessage, response, firstUnread)}
                {MessageCell}
              </div>
            );
          })}
        </IonList>
      );
    }
    return null;
  };

  return (
    <>
      {response && (
        <MessageTopic
          title={response.title}
          participants={response.participants}
        />
      )}
      <IonContent
        id="messagesContent"
        ref={contentRef}
        scrollEvents={true}
        className="ion-no-padding contentLightGrey contentMaxWidth"
        onIonScroll={(event) => {
          if (
            response?.nextPage &&
            !isLoadingMore &&
            event.detail.scrollTop <= 200
          ) {
            fetchMore();
          }
        }}
      >
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <ErrorView
          hidden={!isError}
          onTryAgain={() => {
            fetchMessages();
          }}
        />
        <EmptyView
          hidden={!isEmpty}
          type={response?.sendingEnabled === false
            ? EmptyViewType.MessagesNoSending
            : EmptyViewType.Messages}
        />
        {getContent()}
        <div ref={listBottomRef} />
      </IonContent>
      <IonFooter
        hidden={isLoading || isError || response?.sendingEnabled === false}
        mode="ios"
        className="messagesFooter contentMaxWidth"
      >
        <NewMessage
          conversationId={id}
          onMessageSent={() => {
            post(() => {
              scrollToBottom(true);
            });
          }}
        />
      </IonFooter>
    </>
  );
};

export default withRouter(withMessagesService(Messages));
