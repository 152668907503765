import React, { useContext, useState } from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonLoading,
  IonList,
  IonIcon,
  IonText,
} from "@ionic/react";
import { UserContext } from "providers/User";

import "./Register.css";

import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import EsperiVerificationCodeTextField from "components/EsperiVerificationCodeTextField";
import { isVerificationCodeValid } from "util/Validator";
import { withUserService, WithUserServiceProps } from "service";
import { Redirect, RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

const RegisterVerificationCode = (
  props: WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
) => {
  const { state } = useContext(UserContext);

  const [verificationCode, setVerificationCode] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isVerificationCodeOK, setIsVerificationCodeOK] = useState(true);
  const [isRequesting, seIsRequesting] = useState(false);
  const { t } = useTranslation();

  function requestNewVerificationCode() {
    seIsRequesting(true);
    props.userService
      .sendConfirmationCodeSMS({ invitationToken: state.invitationToken })
      .then(() => {
        seIsRequesting(false);
      })
      .catch((error) => {
        seIsRequesting(false);
        props.handleError(error);
      });
  }

  function checkVerificationCode(e: any) {
    e.preventDefault();
    setIsChecking(true);
    props.userService
      .checkConfirmationCode({
        code: verificationCode,
        confirmationToken: state.confirmationToken,
      })
      .then((response) => {
        setIsChecking(false);
        setIsVerificationCodeOK(true);
        props.history.push("/luoSalasana");
      })
      .catch((error) => {
        setIsChecking(false);
        setIsVerificationCodeOK(false);
      });
  }

  if (!state.confirmationToken) {
    return <Redirect to="/" />;
  }

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isChecking} message={t("validatingCode")} />
      <IonLoading isOpen={isRequesting} message={t("requestingNewCode")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered red" lines="none">
          <IonLabel>{t("verificationCode")}</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("inputVerificationCode")}
          </IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey phoneNumber" lines="none">
          <IonLabel>{state.phoneNumber}</IonLabel>
        </IonItem>
        <IonList lines="none">
          <EsperiVerificationCodeTextField
            verificationCode={verificationCode}
            onVerificationChanged={(verificationCode: string) => {
              setIsVerificationCodeOK(true);
              setVerificationCode(verificationCode);
            }}
            onRequestNewVerificationCode={() => requestNewVerificationCode()}
          />
          <IonItem className="inputInfo" hidden={isVerificationCodeOK}>
            <IonIcon icon={"/assets/ic_alert.svg"} />
            <IonText className="smallText inputAlert" color="red">
              {t("invalidCode")}
            </IonText>
          </IonItem>
        </IonList>
      </IonContent>
      <IonItem>
        <IonButton
          className="buttonRed buttonBottom"
          onClick={(e) => checkVerificationCode(e)}
          disabled={!isVerificationCodeValid(verificationCode)}
        >
          {t("continue")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(RegisterVerificationCode));
