import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSlide,
  IonSlides,
} from "@ionic/react";

import "./OnboardingPage.css";

import { RouteComponentProps, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import { UIContext } from "providers/UI/UIAppState";
import { UIActions } from "../../providers/UI/UIActions";
import { MainTab } from "../../providers/UI";

const OnboardingPage: React.FC<RouteComponentProps> = (props) => {
  const { state, dispatch } = useContext(UIContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const slideCount = 5;

  useEffect(() => {
    if (location.pathname === "/ohje") {
      // @ts-ignore
      document.getElementById("slides")?.slideTo(0);
    }
  }, [location]);

  const onButtonClick = (e: any) => {
    e.preventDefault();
    if (activeIndex < slideCount - 1) {
      // @ts-ignore
      document.getElementById("slides")?.slideNext();
    } else {
      dispatch(UIActions.changeMainTab(MainTab.NewsTab));
      props.history.replace("/etusivu");
    }
  };

  function getButtonText(): string {
    if (activeIndex < slideCount - 1) {
      return t("next");
    } else {
      return t("close");
    }
  }

  const slide1 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_1.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("news").toUpperCase()}
              </span>{" "}
              {t("onboarding1")}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide2 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_2.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">{t("onboarding2")}</IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide3 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_3.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("messages").toUpperCase()}
              </span>{" "}
              {t("onboarding3")}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide4 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_4.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("calendar").toUpperCase()}
              </span>{" "}
              {t("onboarding4")}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide5 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_5.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("nursingHomeDetails").toUpperCase()}
              </span>{" "}
              {t("onboarding5")}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  const slide6 = () => {
    return (
      <IonSlide>
        <IonList className="onboardingSlide" lines="none">
          <IonImg
            className="onboardingBgImage"
            src="/assets/onboarding/onboarding_6.svg"
          />
          <IonItem className="onBoardingInfoText">
            <IonLabel className="ion-text-wrap">
              <span className="onBoardBoldRedText">
                {t("profileAndSettings").toUpperCase()}
              </span>{" "}
              {t("onboarding6")}
            </IonLabel>
          </IonItem>
        </IonList>
      </IonSlide>
    );
  };

  return (
    <IonPage className="onboardingRoot">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      <IonContent className="contentLightGrey contentMaxWidth">
        <IonSlides
          id="slides"
          pager
          onIonSlideWillChange={() => {
            (document.getElementById("slides") as HTMLIonSlidesElement)
              ?.getActiveIndex()
              .then((index: number) => {
                setActiveIndex(index);
              })
              .catch(() => { });
          }}
        >
          {slide1()}
          {slide2()}
          {slide3()}
          {slide5()}
          {slide6()}
        </IonSlides>
      </IonContent>
      <IonFooter className="ion-padding ion-no-border onboardingFooter contentMaxWidth">
        <IonButton
          className="ion-margin-horizontal buttonRed"
          expand="block"
          onClick={onButtonClick}
        >
          {getButtonText()}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default OnboardingPage;
