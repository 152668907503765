import React, { useContext } from "react";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { UserContext } from "providers/User";
import { CalendarContext } from "providers/Calendar/CalendarAppState";
import { HTTP_API_KEY, HTTP_LOGS_ENABLED } from "util/Constants";
import { SessionUtils } from "util/SessionUtils";
import UserService from "./UserService";
import FeedService from "./FeedService";
import CalendarService from "./CalendarService";
import MessagesService from "./MessagesService";
import { MessagesContext } from "../providers/Messages/MessagesAppState";

/*
 AXIOS CONFIGURATIONS
 */
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers["x-api-key"] = HTTP_API_KEY;

const refreshAuthCall = (failedRequest: XMLHttpRequest) =>
  SessionUtils.initSession()
    .then((userSession) => {
      failedRequest.response.config.headers["Authorization"] =
        userSession.IdToken;
      return Promise.resolve();
    })
    .catch(() => {
      return Promise.reject();
    });

createAuthRefreshInterceptor(axios, refreshAuthCall, {
  statusCodes: [401],
});

export function setupAxios(history: any) {
  if (HTTP_LOGS_ENABLED) {
    axios.interceptors.request.use((request) => {
      console.log("HTTP starting request: " + request.url, request);
      return request;
    });
    axios.interceptors.response.use((response) => {
      console.log(
        "HTTP response for " + response.request.responseURL + " :",
        response
      );
      return response;
    });
  }
}

/*
 COMMON MODEL
 */

export interface Media {
  url: string;
}

/*
 SERVICES
 */

export interface WithUserServiceProps {
  userService: UserService;
}

export const withUserService = <P extends WithUserServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithUserServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithUserServiceProps>>
) => {
  const { state, dispatch } = useContext(UserContext);
  const userService = (): UserService => new UserService(state, dispatch);

  return <Component {...(props as P)} userService={userService()} />;
};

export interface WithFeedServiceProps {
  feedService: FeedService;
}

export const withFeedService = <P extends WithFeedServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithFeedServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithFeedServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const feedService = (): FeedService => new FeedService(state);

  return <Component {...(props as P)} feedService={feedService()} />;
};

export interface WithMessagesServiceProps {
  messagesService: MessagesService;
}

export const withMessagesService = <P extends WithMessagesServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithMessagesServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithMessagesServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(MessagesContext);
  const messagesService = (): MessagesService =>
    new MessagesService(state, dispatch);

  return <Component {...(props as P)} messagesService={messagesService()} />;
};

export interface WithCalendarServiceProps {
  calendarService: CalendarService;
}

export const withCalendarService = <P extends WithCalendarServiceProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithCalendarServiceProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithCalendarServiceProps>>
) => {
  const { state } = useContext(UserContext);
  const { dispatch } = useContext(CalendarContext);
  const calendarService = (): CalendarService =>
    new CalendarService(state, dispatch);

  return <Component {...(props as P)} calendarService={calendarService()} />;
};
