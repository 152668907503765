import axios from "axios";
import React from "react";
import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import {
  Message,
  MessagesActionTypes,
  MessagesResponse,
} from "providers/Messages/MessagesActionTypes";
import { UserAppState } from "providers/User/UserReducer";
import { MessagesActions } from "providers/Messages/MessagesActions";

export default class MessagesService extends BaseService {
  /**
   * Using static field for debouncing the mark as read API call.
   */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<MessagesActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<MessagesActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  /*
   API
   */
  fetchConversations(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/conversations");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        this.dispatch(
          MessagesActions.onGetConversationsResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchMessages(
    customerId: string,
    page: string = "0"
  ): Promise<MessagesResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(MessagesActions.clearMessages());
      }
      const url = new URL(HTTP_API_URL + "/conversation/" + customerId);
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.messages.sort((a: Message, b: Message) => {
          // @ts-ignore
          return new Date(a.created) - new Date(b.created);
        });
        this.dispatch(
          MessagesActions.onMessagesResponse(response.data.messages)
        );
        resolve(response.data as MessagesResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  sendMessage(
    customerId: string,
    message: Message,
    retry: boolean = false
  ): Promise<Message> {
    this.dispatch(
      MessagesActions.onNewMessage(message, retry ? message.id : null)
    );
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/conversation/" + customerId);
      try {
        const response = await axios.post(
          url.toString(),
          message,
          this.addAuthorizationHeaders()
        );
        this.dispatch(
          MessagesActions.onNewMessage(response.data as Message, message.id)
        );
        resolve(response.data as Message);
      } catch (e) {
        message.failed = true;
        this.dispatch(MessagesActions.onNewMessage(message, message.id));
        reject(e);
      }
    });
  }

  markAsRead(conversationId: string, message: Message): Promise<any> {
    if (MessagesService.timeout) {
      clearTimeout(MessagesService.timeout);
    }
    return new Promise(async (resolve, reject) => {
      const url = new URL(
        HTTP_API_URL + "/conversation/" + conversationId + "/read"
      );
      try {
        MessagesService.timeout = setTimeout(async () => {
          const response = await axios.post(
            url.toString(),
            { lastMessageId: message.id },
            this.addAuthorizationHeaders()
          );
          this.dispatch(
            MessagesActions.onMarkAsReadResponse(
              conversationId,
              response.data.unreadCount
            )
          );
          resolve(response.data as Message);
        }, 300);
      } catch (e) {
        reject(e);
      }
    });
  }
}
