import {
  ActionType,
  CalendarActionTypes,
  CalendarMonth,
} from "./CalendarActionTypes";

export interface CalendarAppState {
  calendar: CalendarMonth[] | null; // Array containing all upcoming calendar months with events
}

export const initialState: CalendarAppState = {
  calendar: null,
};

export const calendarReducer = (
  state: CalendarAppState,
  action: CalendarActionTypes
) => {
  switch (action.type) {
    case ActionType.GetCalendarResponse: {
      return { ...state, ...action };
    }
    case ActionType.CalendarEventResponse: {
      const calendar = state.calendar;
      calendar?.forEach((month) => {
        const index = month.events.findIndex((value) => {
          return value.id === action.event.id;
        });
        if (index !== -1) {
          month.events.splice(index, 1, action.event);
        }
      });
      return { ...state, calendar };
    }
  }
};
