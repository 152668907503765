import React, { useContext, useState } from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonLoading,
  IonList,
} from "@ionic/react";

import "./Register.css";

import EsperiEmailTextField from 'components/EsperiEmailTextField';
import EsperiHeaderWithoutNavigation from "components/EsperiHeaderWithoutNavigation";
import EsperiPasswordTextField from "components/EsperiPasswordTextField";

import { isPasswordValid } from "util/Validator";
import { UserContext } from "providers/User";
import { withUserService, WithUserServiceProps } from "service";
import { Redirect, RouteComponentProps } from "react-router";
import { MIN_PASSWORD_LENGTH } from "util/Constants";
import { useTranslation } from "react-i18next";
import { withErrorHandler, WithErrorHandlerProps } from "../index";

const RegisterPassword = (
  props: WithUserServiceProps & RouteComponentProps & WithErrorHandlerProps
) => {
  const { state } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [matchPasswordError, setMatchPasswordError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();

  function submitPassword(e: any) {
    e.preventDefault();
    if (!isPasswordValid(password)) {
      setPasswordError(
        t("passwordValidationError", { minLength: MIN_PASSWORD_LENGTH })
      );
    } else if (password !== matchPassword) {
      setMatchPasswordError(t("passwordMismatchError"));
    } else {
      setIsSending(true);
      props.userService
        .setPassword({
          registrationToken: state.registrationToken,
          email: state.email,
          password: password,
        })
        .then((response) => {
          // In this flow AppState doesn't get refreshed before we call fetchConsents,
          // so we need to pass IdToken for authorization manually
          props.userService
            .fetchConsents(response.data.IdToken)
            .then((response) => {
              setIsSending(false);
              if (response.data.consents?.length > 0) {
                props.history.push("/ehdot", {
                  consent: response.data.consents[0],
                });
              } else {
                props.history.replace("/etusivu");
              }
            })
            .catch((error) => {
              setIsSending(false);
              props.handleError(error);
            });
        })
        .catch((error) => {
          setIsSending(false);
          props.handleError(error);
        });
    }
  }

  if (!state.registrationToken) {
    return <Redirect to="/" />;
  }

  return (
    <IonPage className="pageWhite pageWidth centered">
      <IonLoading isOpen={isSending} message={t("creatingPassword")} />
      <IonContent className="contentPaddingHorizontal">
        <EsperiHeaderWithoutNavigation />
        <IonItem className="title centered red" lines="none">
          <IonLabel>{t("createPassword")}</IonLabel>
        </IonItem>
        <IonItem className="body1 centered grey" lines="none">
          <IonLabel className="ion-text-wrap">
            {t("createPasswordHint")}
          </IonLabel>
        </IonItem>
        <IonList lines="none">
          <EsperiEmailTextField
              hidden={true}
              email={state.email}
              onEmailChanged={() => {}}
              error={""}
            />
          <EsperiPasswordTextField
            title={t("choosePassword")}
            password={password}
            onPasswordChanged={(password: string) => {
              setPassword(password);
              setPasswordError("");
            }}
            error={passwordError}
            hasIcon={false}
            showPasswordLength={true}
          />
          <EsperiPasswordTextField
            title={t("typePasswordAgain")}
            password={matchPassword}
            onPasswordChanged={(password: string) => {
              setMatchPassword(password);
              setMatchPasswordError("");
            }}
            error={matchPasswordError}
            hasIcon={false}
            showPasswordLength={false}
          />
        </IonList>
      </IonContent>
      <IonItem>
        <IonButton
          className="buttonRed buttonBottom"
          onClick={(e) => submitPassword(e)}
        >
          {t("continue")}
        </IonButton>
      </IonItem>
    </IonPage>
  );
};

export default withUserService(withErrorHandler(RegisterPassword));
