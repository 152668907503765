import React, { useContext, useEffect, useState } from "react";
import { IonButton, IonContent, IonLabel, IonPage } from "@ionic/react";
import { MainTab } from "providers/UI";
import { UIContext } from "providers/UI/UIAppState";
import { UIActions } from "providers/UI/UIActions";

import Feed from "./Feed/Feed";
import Messages from "./Messages/Messages";

import "./MainPage.css";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import {
  withMessagesService,
  WithMessagesServiceProps,
  withUserService,
  WithUserServiceProps,
} from "service";
import { RouteComponentProps, useLocation } from "react-router";
import { UserUtil } from "util/UserUtil";
import { useTranslation } from "react-i18next";
import { MessagesContext } from "providers/Messages/MessagesAppState";
import PlaceholderContent from "../PlaceholderContent";
import Conversations from "./Conversations/Conversations";
import { SessionUtils } from "util/SessionUtils";
import { UserActions, UserContext } from "providers/User";

const MainPage = (
  props: WithUserServiceProps & WithMessagesServiceProps & RouteComponentProps
) => {
  const { state, dispatch } = useContext(UIContext);
  const { dispatch: userDispatch } = useContext(UserContext);
  const { state: messagesState } = useContext(MessagesContext);
  const [isLoadingConversations, setLoadingConversations] = useState(false);
  const [conversationsError, setConversationsError] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    props.userService
      .fetchConsents()
      .then((response) => {
        if (response.data.consents?.length > 0) {
          props.history.push("/ehdot", {
            consent: response.data.consents[0],
          });
        } else {
          props.userService
            .fetchSettings()
            .then((response) => {
              if (
                UserUtil.emailNotificationsUndefined(response.data.settings)
              ) {
                props.history.push("/ilmoitukset");
              }
            })
            .catch((error) => {
              // TODO How to handle?
            });
        }
      })
      .catch((error) => {
        // TODO How to handle?
      });

    props.userService
      .fetchProfile()
      .then(() => {
        // no-op
      })
      .catch((e) => {
        // TODO FIND GENERIC SOLUTION FOR THIS
        if (e?.response?.status === 401) {
          SessionUtils.clearSession(true);
          userDispatch(UserActions.onSignedOut());
          props.history.replace("/");
        }
      });
  }, []);

  useEffect(() => {
    if (location.pathname === "/etusivu") {
      fetchConversations();
    }
  }, [location]);

  function fetchConversations() {
    // When conversations are fetched, do silent refresh
    const isSilent = messagesState.conversations !== null;
    setLoadingConversations(!isSilent);
    setConversationsError(false);
    props.messagesService
      .fetchConversations()
      .then(() => {
        setLoadingConversations(false);
      })
      .catch(() => {
        setLoadingConversations(false);
        setConversationsError(!isSilent);
      });
  }

  function getMessagesContent() {
    if (state.selectedMainTab === MainTab.MessagesTab) {
      if (isLoadingConversations || conversationsError) {
        return (
          <PlaceholderContent
            isLoading={isLoadingConversations}
            isError={conversationsError}
            onTryAgain={() => {
              fetchConversations();
            }}
          />
        );
      } else if (messagesState.conversations) {
        if (messagesState.conversations.length > 1) {
          return <Conversations />;
        } else if (messagesState.conversations.length > 0) {
          return (
            <Messages
              conversationId={messagesState.conversations[0].id.toString()}
              hasOneConversation={true}
            />
          );
        } else {
          return <IonContent />;
        }
      }
    }
  }

  function getTabs() {
    if (!state.isSplitPaneVisible) {
      return (
        <>
          <div className="mainTabs">
            <div className="mainTabsRow">
              <IonButton
                className="mainTabsButton"
                fill="clear"
                expand="full"
                onClick={() => {
                  dispatch(UIActions.changeMainTab(MainTab.NewsTab));
                }}
              >
                <IonLabel
                  className={
                    state.selectedMainTab === MainTab.NewsTab
                      ? "heading1SemiBold tabSelected"
                      : "heading1SemiBold tabNotSelected"
                  }
                >
                  {t("news")}
                </IonLabel>
              </IonButton>
              <div className="verticalDivider" />
              <IonButton
                className="mainTabsButton"
                fill="clear"
                expand="full"
                onClick={() => {
                  dispatch(UIActions.changeMainTab(MainTab.MessagesTab));
                }}
              >
                <IonLabel
                  className={
                    state.selectedMainTab === MainTab.MessagesTab
                      ? "heading1SemiBold tabSelected"
                      : "heading1SemiBold tabNotSelected"
                  }
                >
                  {t("messages")}
                  <span
                    style={{ top: 15 }}
                    className="unreadBadge"
                    hidden={!messagesState.hasUnreadMessages}
                  />
                </IonLabel>
              </IonButton>
            </div>
          </div>
          <div className="divider" />
        </>
      );
    }
  }

  return (
    <IonPage className="pageWhite">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      {getTabs()}
      <>
        <Feed hidden={state.selectedMainTab === MainTab.MessagesTab} />
        {getMessagesContent()}
      </>
    </IonPage>
  );
};

export default withUserService(withMessagesService(MainPage));
