import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonItem,
  IonLoading,
  IonPage,
  IonText,
  isPlatform,
} from "@ionic/react";

import "./SideMenu.css";

import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { UIContext } from "providers/UI/UIAppState";
import { MainTab } from "providers/UI";
import { UIActions } from "providers/UI/UIActions";
import { withUserService, WithUserServiceProps } from "service";
import { UserContext } from "providers/User";
import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import { HomeItem } from "providers/User/UserActionTypes";
import { useTranslation } from "react-i18next";
import { MessagesContext } from "providers/Messages/MessagesAppState";

const SideMenuDesktop = (props: RouteComponentProps & WithUserServiceProps) => {
  const { state: uiState, dispatch: uiDispatch } = useContext(UIContext);
  const { state: messagesState } = useContext(MessagesContext);
  const { state } = useContext(UserContext);
  const [loadingText, setLoadingText] = useState<string | null>(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (state.AccessToken) {
      props.userService
        .fetchHomes()
        .then(() => {
          // no-op
        })
        .catch((error) => {
          // TODO HANDLE ERROR?
        });
    }
  }, [state.AccessToken]);

  const logOut = (e: any) => {
    e.preventDefault();
    setLoadingText(t("loggingOut"));
    props.userService
      .signOut()
      .then(() => {
        setLoadingText(null);
        props.history.replace("/kirjautuminen");
      })
      .catch(() => {});
  };

  function getClassName(
    path: string,
    tab: MainTab | null,
    fullPath: boolean = false
  ): string {
    let tabValid = true;
    if (tab != null && uiState.selectedMainTab !== tab) {
      tabValid = false;
    }
    let pathValid;
    if (fullPath) {
      pathValid = location.pathname === path;
    } else {
      pathValid = location.pathname.split("/")[1] === path;
    }

    // TODO Make routing better for these tabs
    if (tabValid && !pathValid && tab === MainTab.MessagesTab) {
      pathValid = location.pathname.split("/")[1] === "viestit";
    }

    return tabValid && pathValid ? "tabSelected" : "tabNotSelected";
  }

  return (
    <div className="pageLightGrey">
      <IonPage className="sideMenuDesktop">
        <IonLoading
          isOpen={loadingText !== null}
          message={loadingText as string}
        />
        <IonContent className="sideMenuDesktopContent">
          <EsperiAppHeader
            hideLeftIcon={uiState.isSplitPaneVisible}
            hideEsperiLogo={false}
            hideRightIcon={uiState.isSplitPaneVisible}
            leftIconType={HeaderLeftIcon.Close}
          />
          <div
            className="ion-padding sideMenuCard"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.NewsTab));
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.NewsTab)}>
                {t("news").toUpperCase()}
              </IonText>
            </IonItem>
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/etusivu");
                uiDispatch(UIActions.changeMainTab(MainTab.MessagesTab));
              }}
            >
              <IonText className={getClassName("etusivu", MainTab.MessagesTab)}>
                {t("messages").toUpperCase()}
                <span
                  style={{ top: isPlatform("ios") ? 4 : 10 }}
                  className="unreadBadge"
                  hidden={!messagesState.hasUnreadMessages}
                />{" "}
              </IonText>
            </IonItem>
            <div className="sideMenuDivider" />
            {false && <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/kalenteri");
              }}
            >
              <IonText className={getClassName("kalenteri", null)}>
                {t("calendar").toUpperCase()}
              </IonText>
            </IonItem>}
            {state.homes.map((item: HomeItem) => {
              const name =
                state.homes.length === 1
                  ? t("nursingHomeDetails").toUpperCase()
                  : item.name.toUpperCase();
              return (
                <IonItem
                  key={item.id}
                  className="clickable heading1SemiBold"
                  lines="none"
                  onClick={() => {
                    props.history.replace("/koti/" + item.id);
                  }}
                >
                  <IonText
                    className={getClassName("/koti/" + item.id, null, true)}
                  >
                    {name}
                  </IonText>
                </IonItem>
              );
            })}
            <IonItem
              className="clickable heading1SemiBold"
              lines="none"
              onClick={() => {
                props.history.replace("/ohje");
              }}
            >
              <IonText className={getClassName("ohje", null)}>
                {t("onboarding").toUpperCase()}
              </IonText>
            </IonItem>
            <div className="sideMenuDivider" />
            <div className="marginTop30px" />
            <IonButton
              className="buttonRedOutline"
              expand="block"
              onClick={logOut}
            >
              {t("logOut")}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default withUserService(withRouter(SideMenuDesktop));
