import { MainTab } from "./index";
import { ActionType, UIActionTypes } from "./UIActionTypes";

export class UIActions {
  static setSplitPaneVisible(visible: boolean): UIActionTypes {
    return {
      type: ActionType.SplitPaneVisibilityChanged,
      isSplitPaneVisible: visible,
    };
  }

  static changeMainTab(newTab: MainTab): UIActionTypes {
    return {
      type: ActionType.MainPageTabChanged,
      selectedMainTab: newTab,
    };
  }
}
