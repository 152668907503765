import React from "react";
import { IonRow, IonCol } from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./Messages.css";

interface UnreadMessagesDividerProps {
  unreadCount: number;
}

const UnreadMessagesDivider: React.FC<UnreadMessagesDividerProps> = (props) => {
  const { t } = useTranslation();

  return (
    <IonRow className="messageDivider">
      <IonCol className="ion-no-padding ion-no-margin unreadMessageDividerLine" />
      <IonCol size="auto">
        <p className="body3Bold unreadMessageDividerTitle">
          {t("unreadMessages", { count: props.unreadCount })}
        </p>
      </IonCol>
      <IonCol className="ion-no-padding ion-no-margin unreadMessageDividerLine" />
    </IonRow>
  );
};

export default UnreadMessagesDivider;
