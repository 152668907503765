import { MainTab } from "./index";
import { ActionType, UIActionTypes } from "./UIActionTypes";

export interface UIAppState {
  isSplitPaneVisible: boolean;
  selectedMainTab: MainTab;
}

export const initialState: UIAppState = {
  isSplitPaneVisible: window.matchMedia("(min-width: 768px)").matches,
  selectedMainTab: MainTab.NewsTab,
};

export const reducer = (state: UIAppState, action: UIActionTypes) => {
  switch (action.type) {
    case ActionType.SplitPaneVisibilityChanged:
    case ActionType.MainPageTabChanged:
      return { ...state, ...action };
  }
};
