import { TFunction } from "i18next";
import { AxiosError } from "axios";

/**
 * Utility methods for error handling
 */
export class ErrorUtils {
  static getErrorMessage(error: any, t: TFunction): string {
    if (error as AxiosError) {
      const url = error.response?.config?.url || "";
      if (url.endsWith("signin")) {
        return t("errorDialogLogin");
      }
      if (error.message?.toLowerCase() === "network error") {
        return t("errorDialogNetwork");
      }
    }
    return t("errorDialogGeneral");
  }
}
