import React, { useContext } from "react";
import {
  IonImg,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonToolbar,
  IonHeader,
} from "@ionic/react";
import "components/EsperiHeader.css";
import { withRouter } from "react-router";
import { UIContext } from "providers/UI/UIAppState";
import { getLogo } from 'util/Branding';

const ProfilePageHeader = () => {
  const { state } = useContext(UIContext);

  return (
    <IonHeader>
      <IonToolbar className="contentMaxWidth">
        <IonRow slot="start">
          <IonCol>
            <IonButton
              hidden={state.isSplitPaneVisible}
              fill="clear"
              onClick={() => {
                // @ts-ignore
                document.getElementById("mainMenu")?.toggle();
              }}
            >
              <IonIcon
                className="appTitleIconNormal"
                src="assets/ic_menu.svg"
              />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonImg
          hidden={state.isSplitPaneVisible}
          className="appTitleHeader"
          src={getLogo()}
        />
        <IonRow slot="end">
          <IonCol>
            <IonButton fill="clear">
              <IonIcon
                className="appTitleIconNormal"
                src="assets/ic_user_active.svg"
              />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(ProfilePageHeader);
