import React from "react";
import {
  IonItem,
  IonText,
  IonList,
  IonRow,
  IonImg,
  IonCol,
} from "@ionic/react";

import "./CalendarPage.css";
import moment from "moment";
import { CalendarEvent } from "providers/Calendar/CalendarActionTypes";

interface CalendarEventCellProps {
  event: CalendarEvent;
  onClick: (event: React.MouseEvent<HTMLIonRowElement>) => void;
}

const CalendarEventCell: React.FC<CalendarEventCellProps> = (props) => {
  function getDate() {
    return moment(props.event.start).format("llll");
  }

  return (
    <IonRow className="calendarEventCell" onClick={props.onClick}>
      <IonCol className="ion-no-padding">
        <IonList className="ion-no-padding" lines="none">
          <IonItem className="noPadding">
            <IonText color="grey" className="body2Bold">
              {props.event.name}
            </IonText>
          </IonItem>
          <div className="marginTop10px" />
          <IonItem className="noPadding">
            <IonText color="grey" className="body2 capitalizeFirstLetter">
              {getDate()}
            </IonText>
          </IonItem>
          <div className="marginTop10px" />
          <IonItem className="noPadding">
            <IonText color="grey" className="body2">
              {props.event.location}
            </IonText>
          </IonItem>
          <IonRow
            className="noPadding calendarEventCellJoined"
            hidden={!props.event.registered}
          >
            <IonImg src="/assets/ic_checkmark.svg" />
            <IonText color="grey" className="body2 calendarEventCellJoinedText">
              Olet ilmottautunut
            </IonText>
          </IonRow>
        </IonList>
      </IonCol>
      <IonImg
        className="calendarEventCellChevron"
        src="/assets/ic_chevron_grey.svg"
      />
    </IonRow>
  );
};

export default CalendarEventCell;
