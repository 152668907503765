export enum ActionType {
  GetCalendarResponse = "GetCalendarResponse",
  CalendarEventResponse = "CalendarEventResponse",
}

export interface GetCalendarResponse {
  type: ActionType.GetCalendarResponse;
  calendar: CalendarMonth[];
}

export interface CalendarEventResponse {
  type: ActionType.CalendarEventResponse;
  event: CalendarEvent;
}

export type CalendarActionTypes = GetCalendarResponse | CalendarEventResponse;

/*
 MODEL
 */
export interface CalendarMonth {
  year: number;
  month: number;
  events: CalendarEvent[];
}

export interface CalendarEvent {
  id: number;
  start: string;
  name: string;
  location: string;
  description: string;
  canRegister: boolean;
  registered: boolean;
  notes: string;
}
