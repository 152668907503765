import React from "react";
import { IonContent } from "@ionic/react";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";

interface PlaceholderPageProps {
  isLoading: boolean;
  isError: boolean;
  onTryAgain: () => void;
}

const PlaceholderContent = (props: PlaceholderPageProps) => {
  return (
    <IonContent className="contentPaddingHorizontal contentLightGrey">
      <EsperiSpinner size={SpinnerSize.Large} hidden={!props.isLoading} />
      <ErrorView hidden={!props.isError} onTryAgain={props.onTryAgain} />
    </IonContent>
  );
};

export default PlaceholderContent;
