import { Conversation } from "../providers/Messages/MessagesActionTypes";

export class MessagesUtil {
  static hasUnreadMessages(conversations: Conversation[]): boolean {
    let hasUnreadMessages = false;
    conversations.some((conversation: Conversation) => {
      if (conversation.unreadCount > 0) {
        hasUnreadMessages = true;
        return true;
      }
      return false;
    });
    return hasUnreadMessages;
  }
}
