import React from "react";
import { IonSpinner } from "@ionic/react";
import "./LoadMoreView.css";

interface ErrorViewProps {
  text: string;
}

const LoadMoreView = (props: ErrorViewProps) => {
  return (
    <div key="0" className="loadMoreView">
      <IonSpinner color="grey" name="crescent" style={{ marginRight: 10 }} />
      <p className="body1 grey">{props.text}</p>
    </div>
  );
};

export default LoadMoreView;
