import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonImg,
  IonList,
  IonRow,
  IonText,
  IonSegment,
  IonLabel,
  IonSegmentButton,
  IonHeader,
} from "@ionic/react";
import InfiniteScroll from "react-infinite-scroll-component";
import FeedCell from "./FeedCell";
import { withFeedService, WithFeedServiceProps } from "service";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import { FeedEvent } from "service/FeedService";
import { RouteComponentProps, withRouter } from "react-router";
import { LocalStorageKeys } from "util/Constants";
import { UserContext } from "providers/User";
import { useTranslation } from "react-i18next";
import "./Feed.css";
import ErrorViewSmall from "components/ErrorViewSmall";
import LoadMoreView from "components/LoadMoreView";
import { getShortName } from "../../../util/Branding";

interface FeedProps extends WithFeedServiceProps, RouteComponentProps {
  hidden: boolean;
}

interface SegmentChangeEventDetail {
  value?: string;
}

const Feed = (props: FeedProps) => {
  const { state } = useContext(UserContext);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [chosenFeedTypeFilter, setChosenFeedTypeFilter] = useState<string>("");
  const [feed, setFeed] = useState<FeedEvent[]>([]);
  const [noticeBoardEvent, setNoticeBoardEvent] = useState<FeedEvent | null>(
    null
  );
  const [nextPage, setNextPage] = useState<string>("0");
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
  const [showOnboardingCell, setShowOnboardingCell] = useState(false);
  const [isFetchMoreError, setFetchMoreError] = useState(false);
  const { t } = useTranslation();

  const fetchFeed = useCallback((itemType: string) => {
    setLoading(true);
    setError(false);
    props.feedService
      .fetchFeed("0", itemType)
      .then((response) => {
        setLoading(false);
        setFeed(response.data.feed);
        setNoticeBoardEvent(response.data.noticeBoard[0]);
        setEmpty(response.data.feed.length === 0);
        setNextPage(response.data.nextPage);
        setHasMoreItems(response.data.nextPage !== null);
      })
      .catch(() => {
        setLoading(false);
        setFeed([]);
        setError(true);
        setHasMoreItems(false);
      });
  }, []);

  useEffect(() => {
    fetchFeed(chosenFeedTypeFilter);
  }, [chosenFeedTypeFilter, fetchFeed]);

  const fetchMore = () => {
    setFetchMoreError(false);
    setHasMoreItems(false);
    props.feedService
      .fetchFeed(nextPage, chosenFeedTypeFilter)
      .then((response) => {
        setFeed(feed.concat(response.data.feed));
        setNextPage(response.data.nextPage);
        setHasMoreItems(response.data.nextPage !== null);
      })
      .catch(() => {
        setFetchMoreError(true);
        setHasMoreItems(false);
      });
  };

  useEffect(() => {
    const hidden =
      isLoading ||
      isError ||
      localStorage.getItem(LocalStorageKeys.SeenOnboarding) !== null ||
      state.firstName.length === 0;
    setShowOnboardingCell(!hidden);
  }, [state.firstName, isLoading, isError]);

  const renderNoticeCell = () => {
    if (noticeBoardEvent) {
      return (
        <IonRow
          key="notice"
          className="feedNoticeCell"
          hidden={props.hidden}
          onClick={() => {
            props.history.push("/etusivu/" + noticeBoardEvent.url, {
              event: noticeBoardEvent,
            });
          }}
        >
          <IonCol size="auto">
            <IonImg src="/assets/ic_alert_white.svg" />
          </IonCol>
          <IonCol>
            <IonText className="body2Bold white">
              {noticeBoardEvent.title}
            </IonText>
          </IonCol>
          <IonCol size="auto">
            <IonImg src="/assets/ic_chevron_white.svg" />
          </IonCol>
        </IonRow>
      );
    }
  };

  const renderOnboardingCell = () => {
    return (
      <IonRow
        key="onboarding"
        hidden={!showOnboardingCell}
        className="feedOnboardingCell"
        onClick={() => {
          localStorage.setItem(LocalStorageKeys.SeenOnboarding, "true");
          props.history.push("/ohje");
          setTimeout(() => {
            setShowOnboardingCell(false);
          }, 500);
        }}
      >
        <IonCol size="auto" className="feedOnboardingCellCircle" />
        <IonCol className="feedOnboardingCellTextContainer">
          <IonText className="body2">
            {t("onboardingInfo", { name: state.firstName })}
          </IonText>
        </IonCol>
        <IonCol
          size="auto"
          className="feedOnboardingCellCloseImage"
          onClick={(e) => {
            e.stopPropagation();
            localStorage.setItem(LocalStorageKeys.SeenOnboarding, "true");
            setShowOnboardingCell(false);
          }}
        >
          <IonImg src="/assets/ic_close.svg" />
        </IonCol>
      </IonRow>
    );
  };

  function renderFetchMoreError() {
    if (isFetchMoreError) {
      return (
        <ErrorViewSmall
          title={t("feedLoadingMoreError")}
          onTryAgain={() => {
            setFetchMoreError(false);
            setHasMoreItems(true);
          }}
        />
      );
    }
  }
  return (
    <>
      <IonContent
        className="contentLightGrey contentMaxWidth contentNoOverflow"
        hidden={props.hidden}
      >
        <IonHeader slot="fixed">
          <IonSegment
            value={chosenFeedTypeFilter}
            onIonChange={(e) => setChosenFeedTypeFilter(e.detail.value ?? "")}
            className="ionSegmentFeed"
          >
            <IonSegmentButton className="feedSegmentButton" value="">
              <IonLabel>{t("feedFilter_all")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="feedSegmentButton" value="customer">
              <IonLabel>{t("feedFilter_customer")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="feedSegmentButton" value="home">
              <IonLabel>{t("feedFilter_home")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="feedSegmentButton" value="general">
              <IonLabel>{getShortName()}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonHeader>

        <div id="contentId" className="feed">
          <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
          <ErrorView
            hidden={!isError}
            onTryAgain={() => {
              fetchFeed(chosenFeedTypeFilter);
            }}
          />
          <EmptyView hidden={!isEmpty} type={EmptyViewType.News} />

          <InfiniteScroll
            scrollableTarget="contentId"
            dataLength={feed.length}
            next={fetchMore}
            hasMore={hasMoreItems}
            loader={<LoadMoreView text={t("feedLoadingMore")} />}
          >
            <IonList className="feedList ion-padding" lines="none">
              {renderNoticeCell()}
              {renderOnboardingCell()}
              {feed.map((item: FeedEvent) => {
                return (
                  <FeedCell
                    key={item.url}
                    event={item}
                    onClick={() => {
                      props.history.push("/etusivu/" + item.url, {
                        event: item,
                        feedService: props.feedService,
                      });
                    }}
                  />
                );
              })}
              {renderFetchMoreError()}
            </IonList>
          </InfiniteScroll>
        </div>
      </IonContent>
    </>
  );
};

export default withRouter(withFeedService(Feed));
