import React from "react";
import { IonRow, IonText, IonGrid, IonImg } from "@ionic/react";
import "./EmptyView.css";
import { useTranslation } from "react-i18next";

export enum EmptyViewType {
  News,
  Messages,
  MessagesNoSending,
  Calendar,
}

interface EmptyViewProps {
  hidden: boolean;
  type: EmptyViewType;
}

const EmptyView = (props: EmptyViewProps) => {
  const { t } = useTranslation();

  function getImage() {
    switch (props.type) {
      case EmptyViewType.News:
        return "/assets/graphic/news.svg";
      case EmptyViewType.Messages:
      case EmptyViewType.MessagesNoSending:
        return "/assets/graphic/messages.svg";
      case EmptyViewType.Calendar:
        return "/assets/graphic/calendar.png";
    }
  }

  function getBody() {
    switch (props.type) {
      case EmptyViewType.News:
        return t("newsEmpty");
      case EmptyViewType.MessagesNoSending:
        return t("messagesEmptyNoSending");
      case EmptyViewType.Messages:
        return t("messagesEmpty");
      case EmptyViewType.Calendar:
        return t("calendarEmpty");
    }
  }

  return (
    <IonGrid hidden={props.hidden} className="emptyView contentMaxWidth">
      <div className="marginAuto">
        <IonRow>
          <IonImg className="centered emptyImage" src={getImage()} />
        </IonRow>
        <IonRow>
          <IonText className="centered body1Bold emptyTitle">
            {getBody()}
          </IonText>
        </IonRow>
      </div>
    </IonGrid>
  );
};

export default EmptyView;
