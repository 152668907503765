import React, { useContext, useState } from "react";
import { UserContext } from "providers/User";
import { Redirect, Route } from "react-router";
import { useTranslation } from "react-i18next";
import { IonAlert } from "@ionic/react";
import { ErrorUtils } from "util/ErrorUtils";

export const PrivateRoute: React.FC<any> = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentType<any>;
}) => {
  const { state } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        state.AccessToken !== "" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export interface WithErrorHandlerProps {
  handleError: (error: any) => void;
}

export const withErrorHandler = <P extends WithErrorHandlerProps>(
  Component: React.ComponentType<P>
): React.FC<Pick<P, Exclude<keyof P, keyof WithErrorHandlerProps>>> => (
  props: Pick<P, Exclude<keyof P, keyof WithErrorHandlerProps>>
) => {
  const [errorText, setErrorText] = useState("");
  const { t } = useTranslation();
  const handleError = (error: any) => {
    setErrorText(ErrorUtils.getErrorMessage(error, t));
  };

  return (
    <>
      <IonAlert
        isOpen={errorText !== ""}
        header={t("error")}
        message={errorText}
        onDidDismiss={() => {
          setErrorText("");
        }}
        buttons={[{ text: t("OK"), cssClass: "blue" }]}
      />
      <Component {...(props as P)} handleError={handleError} />
    </>
  );
};
