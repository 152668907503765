import React, { useEffect, useState } from "react";
import { IonCol, IonIcon, IonImg, IonItem, IonText } from "@ionic/react";
import moment from "moment";
import {
  FeedEvent,
  FeedEventItemReaction,
  FeedEventItemType,
} from "service/FeedService";
import { useTranslation } from "react-i18next";
import { withFeedService, WithFeedServiceProps } from "service";
import "./Feed.css";
import "../MainPage.css";

interface FeedCellProps extends WithFeedServiceProps {
  event: FeedEvent;
  onClick: () => void;
}

const FeedCell = (props: FeedCellProps) => {
  const [isLiked, setIsLiked] = useState(getIsLiked());
  useEffect(() => {
    // this updates isLiked when the value is changed in details page
    if (props.event.reactions) {
      setIsLiked(getIsLiked());
    }
  }, [props.event.reactions]);
  const { t } = useTranslation();

  function getIsLiked() {
    return (
      props.event.reactions?.find(
        (value) => value === FeedEventItemReaction.Like
      ) !== undefined
    );
  }

  function toggleLike() {
    setIsLiked(!isLiked);
    props.feedService
      .likePost(props.event.id, !isLiked)
      .then((response) => {
        if (response?.data) {
          props.event.reactions = response.data.reactions;
          setIsLiked(getIsLiked());
        }
      })
      .catch(() => {
        setIsLiked(isLiked);
      });
  }

  function getTopLeftTextAndStyle(): string[] {
    switch (props.event.itemType) {
      case FeedEventItemType.Customer:
        return [props.event.customerName, "feedCellCustomer body3 grey"];
      default:
        return [props.event.authorName, "feedCellAuthor body3 grey"];
    }
  }

  function getTopRightText(): string {
    switch (props.event.itemType) {
      case FeedEventItemType.Customer:
        return props.event.authorName;
      default:
        return "";
    }
  }

  const topLeftTextAndStyle = getTopLeftTextAndStyle();
  const topRightText = getTopRightText();
  const timeText = t("newsItemTime", {
    date: moment(props.event.created).format("ddd D.M"),
    time: moment(props.event.created).format("HH:mm"),
  });

  return (
    <div
      className="feedCell"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <IonItem className="feedCellTitleContainer ion-no-padding">
        <IonText className={topLeftTextAndStyle[1]}>
          {topLeftTextAndStyle[0]}
        </IonText>
        <div style={{ flexGrow: 1 }} />
        <IonText className="feedCellOtherTitle body3 grey">{timeText}</IonText>
        <IonText
          hidden={topRightText.length === 0}
          className="feedCellOtherTitle body3 grey"
        >
          {topRightText}
        </IonText>
      </IonItem>
      <IonItem className="divider" />
      <IonCol className="feedBottomContainer">
        <IonCol
          className="ion-no-padding ion-padding-top ion-padding-start ion-padding-end"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <IonText
            hidden={props.event.title === null}
            className="ion-padding-bottom body2Bold grey"
          >
            {props.event.title}
          </IonText>
          <IonText className="body2 grey ion-padding-bottom">
            {props.event.lead}
          </IonText>
        </IonCol>
        <IonCol
          style={{ background: "white" }}
          className="ion-no-padding"
          size="auto"
          hidden={props.event.headerImage === null}
        >
          <IonItem className="ion-no-padding noPadding">
            <IonIcon
              hidden={!props.event.headerVideo?.url}
              className="videoPlayOverlay"
              src={"/assets/ic_play.svg"}
            ></IonIcon>
            <img
              alt=""
              className="ion-no-margin feedCellImage"
              src={props.event.headerImage?.url}
            />
          </IonItem>
        </IonCol>
      </IonCol>
      <IonItem
        hidden={props.event.itemType !== FeedEventItemType.Customer}
        className="ion-no-padding noPadding"
      >
        <IonImg
          className="pointer feedCellLike"
          src={isLiked ? "assets/ic_heart_filled.svg" : "assets/ic_heart.svg"}
          onClick={(e) => {
            e.stopPropagation();
            toggleLike();
          }}
        />
        {props.event.headerImage && (
          <a
            className="pointer feedCellDownload"
            href={props.event.headerVideo?.url ?? props.event.headerImage?.url}
            download
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IonImg src={"assets/ic_download.svg"} />
          </a>
        )}
      </IonItem>
    </div>
  );
};

export default withFeedService(FeedCell);
