import React from "react";
import { IonList, IonItem, IonImg } from "@ionic/react";
import "./EsperiHeader.css";
import { getLogo } from 'util/Branding';

const EsperiHeaderWithoutNavigation = () => {
  return (
    <IonList className="ion-no-border" lines="none">
      <IonItem className="withoutNavigationTopSpacer" />
      <IonImg className="withoutNavigationTitle" src={getLogo()} />
      <IonItem className="withoutNavigationBottomSpacer" />
    </IonList>
  );
};

export default EsperiHeaderWithoutNavigation;
