import axios from "axios";
import React from "react";
import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";
import { UserAppState } from "providers/User/UserReducer";
import { CalendarActionTypes } from "providers/Calendar/CalendarActionTypes";
import { CalendarActions } from "providers/Calendar/CalendarActions";

export default class CalendarService extends BaseService {
  private readonly dispatch: React.Dispatch<CalendarActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<CalendarActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  /*
   API
   */
  fetchEvents(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/calendar");
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        this.dispatch(CalendarActions.onGetCalendarResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchEvent(id: string): Promise<any> {
    const url = new URL(HTTP_API_URL + "/calendar/" + id);
    return axios.get(url.toString(), this.addAuthorizationHeaders());
  }

  registerEvent(id: string, notes: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/calendar/" + id + "/register");
      try {
        const response = await axios.post(
          url.toString(),
          { notes },
          this.addAuthorizationHeaders()
        );
        this.dispatch(CalendarActions.onCalendarEventResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  unregisterEvent(id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/calendar/" + id + "/unregister");
      try {
        const response = await axios.post(
          url.toString(),
          null,
          this.addAuthorizationHeaders()
        );
        this.dispatch(CalendarActions.onCalendarEventResponse(response.data));
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}
