import React, { useContext, useEffect, useState } from "react";
import { IonContent, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";

import "./CalendarPage.css";

import EsperiAppHeader, { HeaderLeftIcon } from "components/EsperiAppHeader";
import CalendarEventCell from "./CalendarEventCell";
import PageSection from "components/PageSection";
import { RouteComponentProps, useLocation } from "react-router";
import { UIContext } from "providers/UI/UIAppState";
import { withCalendarService, WithCalendarServiceProps } from "service";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import EmptyView, { EmptyViewType } from "components/EmptyView";
import moment from "moment";
import {
  CalendarMonth,
  CalendarEvent,
} from "providers/Calendar/CalendarActionTypes";
import { CalendarContext } from "providers/Calendar/CalendarAppState";
import { useTranslation } from "react-i18next";

const CalendarPage = (
  props: RouteComponentProps & WithCalendarServiceProps
) => {
  const { state } = useContext(UIContext);
  const { state: calendarState } = useContext(CalendarContext);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/kalenteri") {
      fetchEvents();
    }
  }, [location]);

  function fetchEvents() {
    // When calendar is fetched, do silent refresh
    const isSilent = calendarState.calendar !== null;
    setLoading(!isSilent);
    setError(false);
    props.calendarService
      .fetchEvents()
      .then((response) => {
        setLoading(false);
        setEmpty(response.data.calendar.length === 0);
      })
      .catch(() => {
        setLoading(false);
        setError(!isSilent);
      });
  }

  function getSectionTitle(item: CalendarMonth): string {
    return moment()
      .set("year", item.year)
      .set("month", item.month)
      .format("MMMM YYYY");
  }

  const onItemClicked = (e: any, event: CalendarEvent) => {
    e.preventDefault();
    props.history.push("/kalenteri/" + event.id, { event });
  };

  return (
    <IonPage className="pageLightGrey">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      <IonContent className="contentMaxWidth">
        {!state.isSplitPaneVisible && (
          <div>
            <IonItem className="centered pageHeader" lines="none">
              <IonLabel color="red" className="heading1SemiBold">
                {t("calendar").toUpperCase()}
              </IonLabel>
            </IonItem>
            <div className="pageHeaderDivider" />
          </div>
        )}
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <ErrorView
          hidden={!isError}
          onTryAgain={() => {
            fetchEvents();
          }}
        />
        <EmptyView
          hidden={!isEmpty || isLoading}
          type={EmptyViewType.Calendar}
        />
        <IonList className="ion-padding-horizontal" lines="none">
          {calendarState.calendar?.map((item) => {
            return (
              <>
                <PageSection title={getSectionTitle(item)} />
                {item.events.map((event) => {
                  return (
                    <CalendarEventCell
                      event={event}
                      onClick={(e) => {
                        onItemClicked(e, event);
                      }}
                    />
                  );
                })}
              </>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withCalendarService(CalendarPage);
