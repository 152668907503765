import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
} from "@ionic/react";

import "./CalendarEventDetailsPage.css";

import PageHeader from "components/PageHeader";
import { withCalendarService, WithCalendarServiceProps } from "service";
import { RouteComponentProps, useLocation, useParams } from "react-router";
import EsperiSpinner, { SpinnerSize } from "components/EsperiSpinner";
import ErrorView from "components/ErrorView";
import moment from "moment";
import { CalendarEvent } from "providers/Calendar/CalendarActionTypes";
import { useTranslation } from "react-i18next";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";

const CalendarEventDetailsPage = (
  props: RouteComponentProps & WithCalendarServiceProps
) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [event, setEvent] = useState<CalendarEvent>();
  const [message, setMessage] = useState("");
  const [isRegistering, setRegistering] = useState(false);
  const [alert, setAlert] = useState("");
  const location = useLocation();
  const { eventId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    fetchEvent();
  }, [eventId]);

  function fetchEvent() {
    if (eventId) {
      setLoading(true);
      setError(false);
      props.calendarService
        .fetchEvent(eventId)
        .then((response) => {
          setLoading(false);
          setEvent(response.data);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }

  function registerEvent() {
    if (eventId) {
      setRegistering(true);
      setAlert("");
      props.calendarService
        .registerEvent(eventId, message)
        .then((response) => {
          setMessage("");
          setRegistering(false);
          setEvent(response.data);
        })
        .catch(() => {
          setRegistering(false);
          setAlert(t("calendarRegisterFailed"));
        });
    }
  }

  function unregisterEvent() {
    if (eventId) {
      setRegistering(true);
      setAlert("");
      props.calendarService
        .unregisterEvent(eventId)
        .then((response) => {
          setRegistering(false);
          setEvent(response.data);
        })
        .catch(() => {
          setRegistering(false);
          setAlert(t("calendarUnregisterFailed"));
        });
    }
  }

  function getTitle() {
    // @ts-ignore
    return location.state?.event?.name || event?.name;
  }

  function getDate() {
    if (event) {
      return moment(event.start).format("llll");
    }
    return "";
  }

  function getDescription() {
    return event?.description || "";
  }

  function getButtonText() {
    if (event?.registered) {
      return t("unregister");
    }
    return t("register");
  }

  const onClickRegister = () => {
    if (event?.registered) {
      unregisterEvent();
    } else {
      registerEvent();
    }
  };

  function renderRegister() {
    if (event?.canRegister) {
      return (
        <>
          <IonItem className="noPadding grey">
            <p className="body2Bold calendarEventDetailsBody">
              {t("calendarEventRegisterSection")}
            </p>
          </IonItem>
          <div className="calendarRegisterDivider" />
          <TextareaAutosize
            className="calendarRegisterInput"
            value={event?.registered ? event?.notes : message}
            placeholder={t("calendarEventRegisterPlaceholder")}
            disabled={event?.registered}
            style={{ opacity: event?.registered ? 0.5 : 1 }}
            maxRows={10}
            inputMode="text"
            onChange={(e) => setMessage((e.target as HTMLInputElement).value)}
          />
          <IonItem>
            <IonButton
              hidden={isRegistering}
              className="buttonRed buttonAttend"
              onClick={onClickRegister}
            >
              {getButtonText()}
            </IonButton>
            <IonSpinner
              className="calendarEventDetailsSpinner"
              color="primary"
              name="crescent"
              hidden={!isRegistering}
            />
          </IonItem>
        </>
      );
    }
  }

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <PageHeader title={getTitle()} />
      <IonContent className="ion-padding contentMaxWidth">
        <EsperiSpinner size={SpinnerSize.Large} hidden={!isLoading} />
        <ErrorView
          hidden={!isError}
          onTryAgain={() => {
            fetchEvent();
          }}
        />
        <IonAlert
          isOpen={alert.length > 0}
          header={"Virhe"}
          message={alert}
          buttons={[{ text: t("OK"), cssClass: "blue" }]}
        />
        <IonList lines="none" hidden={isLoading || isError}>
          <IonItem className="noPadding">
            <IonText color="grey" className="body2 calendarEventDetailsBody">
              {getDate()}
            </IonText>
          </IonItem>
          <IonItem className="noPadding">
            <IonText color="grey" className="body2 calendarEventDetailsBody">
              {event?.location}
            </IonText>
          </IonItem>
          <div className="marginTop20px" />
          <IonItem className="noPadding grey">
            <p
              className="body2 calendarEventDetailsBody"
              dangerouslySetInnerHTML={{ __html: getDescription() }}
            />
          </IonItem>
          <div className="marginTop20px" />
          {renderRegister()}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withCalendarService(CalendarEventDetailsPage);
