import {
  ActionType,
  ConsentsResponse,
  GetConfirmInvitationResponse,
  GetHomesResponse,
  PostConfirmCodeResponse,
  PostConfirmInvitationResponse,
  PostSigninResponse,
  PostSignupResponse,
  SettingsResponse,
  UserActionTypes,
  UserProfileResponse,
} from "./UserActionTypes";
import { UserSession } from "service/UserService";

export class UserActions {
  static initialized(session: UserSession | null): UserActionTypes {
    return {
      type: ActionType.Initialized,
      session: session,
      initialized: true,
    };
  }

  static onGetConfirmInvitationResponse(
    invitationToken: string,
    response: GetConfirmInvitationResponse
  ): UserActionTypes {
    return {
      type: ActionType.GetConfirmInvitationResponse,
      invitationToken,
      firstName: response.firstName,
      lastName: response.lastName,
      phoneNumber: response.phoneNumber,
      email: response.email,
    };
  }

  static onPostConfirmInvitationResponse(
    response: PostConfirmInvitationResponse
  ): UserActionTypes {
    return {
      type: ActionType.PostConfirmInvitationResponse,
      confirmationToken: response.confirmationToken,
    };
  }

  static onPostConfirmCodeResponse(
    response: PostConfirmCodeResponse
  ): UserActionTypes {
    return {
      type: ActionType.PostConfirmCodeResponse,
      registrationToken: response.registrationToken,
    };
  }

  static onPostSignupResponse(response: PostSignupResponse): UserActionTypes {
    return {
      type: ActionType.PostSignupResponse,
      IdToken: response.IdToken,
      RefreshToken: response.RefreshToken,
      AccessToken: response.AccessToken,
      ClientId: response.ClientId,
      UserPoolId: response.UserPoolId,
    };
  }

  static onConsentsResponse(response: ConsentsResponse): UserActionTypes {
    return {
      type: ActionType.ConsentsResponse,
      consents: response.consents,
    };
  }

  static onPostSigninResponse(response: PostSigninResponse): UserActionTypes {
    return {
      type: ActionType.PostSigninResponse,
      IdToken: response.IdToken,
      RefreshToken: response.RefreshToken,
      AccessToken: response.AccessToken,
      ClientId: response.ClientId,
      UserPoolId: response.UserPoolId,
    };
  }

  static onRecoveryEmailSent(email: string): UserActionTypes {
    return {
      type: ActionType.RecoveryEmailSent,
      recoveryEmail: email,
    };
  }

  static onSignedOut(): UserActionTypes {
    return {
      type: ActionType.SignedOut,
    };
  }

  static onSettingsResponse(response: SettingsResponse): UserActionTypes {
    return {
      type: ActionType.SettingsResponse,
      settings: response.settings,
    };
  }

  static onUserProfileResponse(response: UserProfileResponse): UserActionTypes {
    return {
      type: ActionType.UserProfileResponse,
      firstName: response.firstName,
      lastName: response.lastName,
      phoneNumber: response.phoneNumber,
      email: response.email,
    };
  }

  static onGetHomesResponse(response: GetHomesResponse): UserActionTypes {
    return {
      type: ActionType.GetHomesResponse,
      homes: response.homes,
    };
  }
}
