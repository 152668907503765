import {
  ActionType,
  CalendarActionTypes,
  CalendarEvent,
  GetCalendarResponse,
} from "./CalendarActionTypes";

export class CalendarActions {
  static onGetCalendarResponse(
    response: GetCalendarResponse
  ): CalendarActionTypes {
    return {
      type: ActionType.GetCalendarResponse,
      calendar: response.calendar,
    };
  }

  static onCalendarEventResponse(event: CalendarEvent): CalendarActionTypes {
    return {
      type: ActionType.CalendarEventResponse,
      event: event,
    };
  }
}
