import React, { useContext, useEffect } from "react";
import { IonPage, IonContent, IonImg, IonRow } from "@ionic/react";
import { parse } from "query-string";
import "./SplashPage.css";
import { UserActions, UserContext } from "providers/User";
import { SessionUtils } from "util/SessionUtils";
import { RouteComponentProps } from "react-router";
import { setupAxios, withUserService, WithUserServiceProps } from "service";
import { getLogo } from 'util/Branding';

const SplashPage = (props: RouteComponentProps & WithUserServiceProps) => {
  const { dispatch } = useContext(UserContext);

  /**
   * Check query parameters for invitation or recover password token and redirect user accordingly
   * @param path url where to redirect in case no query parameters exist
   */
  function checkForToken(path: string) {
    const invitationToken = parse(props.location.search).invitationToken;
    if (invitationToken && typeof invitationToken === "string") {
      SessionUtils.clearSession(true);
      dispatch(UserActions.onSignedOut());
      props.userService
        .checkInvitationToken(invitationToken)
        .then(() => {
          props.history.replace("/rekisteröityminen");
        })
        .catch(() => {
          props.history.replace(path);
        });
    } else {
      const recoveryToken = parse(props.location.search).recoveryToken;
      if (recoveryToken && typeof recoveryToken === "string") {
        props.history.replace("/palautaSalasana", { recoveryToken });
      } else {
        props.history.replace(path);
      }
    }
  }

  useEffect(() => {
    setupAxios(props.history);

    SessionUtils.initSession()
      .then((session) => {
        dispatch(UserActions.initialized(session));
        checkForToken("/etusivu");
      })
      .catch(() => {
        dispatch(UserActions.initialized(null));
        checkForToken("/kirjautuminen");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage className="pageWhite">
      <IonContent>
        <IonRow className="splashContent">
          <div style={{ flex: 1 }} />
          <IonImg className="splashImage" src={getLogo()} />
          <div style={{ flex: 1 }} />
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default withUserService(SplashPage);
