import React, { useContext } from "react";
import { IonContent, IonList } from "@ionic/react";

import { RouteComponentProps, withRouter } from "react-router";
import { MessagesContext } from "providers/Messages/MessagesAppState";
import "./Conversations.css";
import ConversationCell from "./ConversationCell";
import { Conversation } from "providers/Messages/MessagesActionTypes";

const Conversations = (props: RouteComponentProps) => {
  const { state } = useContext(MessagesContext);

  return (
    <IonContent className="contentLightGrey contentMaxWidth">
      <IonList className="conversationsList ion-padding" lines="none">
        {state.conversations?.map((conversation: Conversation) => {
          return (
            <ConversationCell
              key={conversation.id}
              conversation={conversation}
              onClick={() => {
                props.history.push("/viestit/" + conversation.id);
              }}
            />
          );
        })}
      </IonList>
    </IonContent>
  );
};

export default withRouter(Conversations);
