import { STATE_LOGS_ENABLED } from 'util/Constants';

export const logger = (reducer: any, name: string) => {
  return (state: any, action: any) => {
    if (STATE_LOGS_ENABLED) {
      console.log(
        "%cPrevious %s State:",
        "color: #9E9E9E; font-weight: 700;",
        name,
        state
      );
      console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
      console.log(
        "%cNext %s State:",
        "color: #47B04B; font-weight: 700;",
        name,
        reducer(state, action)
      );
    }
    return reducer(state, action);
  };
};
