import moment from "moment";
import { TFunction } from "i18next";

export class Formatter {
  static boldParts(text: string, boldParts: string[] | null): string {
    boldParts?.forEach((highlight) => {
      text = text.replace(RegExp(highlight, "g"), `<b>${highlight}</b>`);
    });
    return text;
  }

  static getMessageSendDate(date: moment.Moment, t: TFunction): string {
    if (moment().isSame(date, "day")) {
      return t("today");
    } else if (moment().subtract(1, "day").isSame(date, "day")) {
      return t("yesterday");
    } else if (moment().isSame(date, "weeks")) {
      return t("thisWeek");
    } else if (moment().subtract(1, "weeks").isSame(date, "weeks")) {
      return t("lastWeek");
    } else {
      return date.calendar();
    }
  }

  static getMessageSendTime(created: string, t: TFunction): string {
    const date = moment(created);
    if (moment().isSame(date, "day")) {
      return date.format("LT");
    } else if (moment().subtract(1, "day").isSame(date, "day")) {
      return t("yesterday") + " " + date.format("LT");
    } else if (moment().isSame(date, "weeks")) {
      return date.format("dddd LT");
    } else {
      return date.format("DD.MM.YYYY LT");
    }
  }
}
