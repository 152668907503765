import React, { Suspense, useContext, useEffect, useRef } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { IonApp, IonPage, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import LoginPage from "pages/Login/LoginPage";
import ForgotPasswordPage from "pages/ForgotPassword/ForgotPasswordPage";
import RegisterPersonalInfo from "pages/Register/RegisterPersonalInfo";
import RegisterVerificationCode from "pages/Register/RegisterVerificationCode";
import RegisterPassword from "pages/Register/RegisterPassword";
import RegisterEmailNotifications from "pages/Register/RegisterEmailNotifications";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "theme/variables.css";
import "theme/common.css";

import { UserContext, UserContextProvider } from "providers/User/UserAppState";
import { UIContext, UIContextProvider } from "providers/UI/UIAppState";
import ConsentPage from "pages/Consent/ConsentPage";
import SplashPage from "pages/Splash/SplashPage";
import { PrivateRoute } from "pages";
import OnboardingPage from "pages/Onboarding/OnboardingPage";
import SideMenu from "pages/SideMenu/SideMenu";
import MainPage from "pages/Main/MainPage";
import ProfilePage from "pages/Profile/ProfilePage";
import CalendarPage from "pages/Calendar/CalendarPage";
import CalendarEventDetailsPage from "pages/Calendar/CalendarEventDetails/CalendarEventDetailsPage";
import FeedEventDetailsPage from "pages/Main/Feed/FeedEventDetails/FeedEventDetailsPage";
import { UIActions } from "providers/UI/UIActions";
import RecoverPasswordPage from "pages/ForgotPassword/RecoverPasswordPage";
import { CalendarContextProvider } from "providers/Calendar/CalendarAppState";
import { MessagesContextProvider } from "providers/Messages/MessagesAppState";
import MessagesPage from "pages/Main/Messages/MessagesPage";
import NursingHomePage from "pages/NursingHome/NursingHomePage";
import { UserAppState } from "providers/User/UserReducer";
import { UIAppState } from "providers/UI/UIReducer";
import SideMenuDesktop from "pages/SideMenu/SideMenuDesktop";
import TermsPage from "pages/Terms/TermsPage";
import PrivacyPolicyPage from "pages/PrivacyPolicy/PrivacyPolicyPage";
import { getPageTitle, getFavicon, getManifest, getThemeColor } from './util/Branding';

// @ts-ignore
const App: React.FC = () => {
  const routerRef = useRef<IonReactRouter>(null);

  const WindowResize = () => {
    const { state } = useContext(UserContext);
    const { state: uiState, dispatch } = useContext(UIContext);
    const location = useLocation();

    const onWindowResize = () => {
      dispatch(UIActions.setSplitPaneVisible(window.innerWidth >= 768));
    };

    useEffect(() => {
      // Dynamically set brand assets
      document.title = getPageTitle();
      const favicon = document.getElementById('favicon-placeholder');
      if (favicon) {
        favicon.setAttribute('href', getFavicon());
      }
      const manifest = document.getElementById('manifest-placeholder');
      if (manifest) {
        manifest.setAttribute('href', getManifest());
      }
      document.documentElement.style.setProperty('--ion-customer-theme', getThemeColor());
    }, []);

    useEffect(() => {
      window.addEventListener("resize", onWindowResize);
      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    });

    function isMenuDisabled(userAppState: UserAppState) {
      return (
        !userAppState.AccessToken ||
        location.pathname === "/ehdot" ||
        location.pathname === "/ilmoitukset" ||
        location.pathname === "/luoSalasana"
      );
    }

    function getMenu(userAppState: UserAppState, uiAppState: UIAppState) {
      if (isMenuDisabled(userAppState)) {
        return null;
      }
      if (uiAppState.isSplitPaneVisible) {
        return <SideMenuDesktop />;
      }
      return <SideMenu />;
    }

    function getMainPageLeft(
      userAppState: UserAppState,
      uiAppState: UIAppState
    ) {
      if (isMenuDisabled(userAppState)) {
        return 0;
      }
      if (uiAppState.isSplitPaneVisible) {
        return 320;
      }
      return 0;
    }

    return (
      <>
        {getMenu(state, uiState)}
        <IonPage
          id="mainPage"
          style={{
            left: getMainPageLeft(state, uiState),
          }}
        >
          <IonRouterOutlet id="router">
            <Route path="/" component={SplashPage} exact />
            <Route path="/kirjautuminen" component={LoginPage} exact />
            <Route
              path="/unohtuikoSalasana"
              component={ForgotPasswordPage}
              exact
            />
            <Route
              path="/palautaSalasana"
              component={RecoverPasswordPage}
              exac
            />
            <Route
              path="/rekisteröityminen"
              component={RegisterPersonalInfo}
              exact
            />
            <Route
              path="/varmistuskoodi"
              component={RegisterVerificationCode}
              exact
            />
            <Route path="/luoSalasana" component={RegisterPassword} exact />
            <Route path="/ehdot" component={ConsentPage} exact />
            <PrivateRoute
              path="/ilmoitukset"
              component={RegisterEmailNotifications}
              exact
            />
            <PrivateRoute path="/ohje" component={OnboardingPage} exact />
            <PrivateRoute path="/etusivu" component={MainPage} exact />
            <PrivateRoute
              path="/etusivu/:id"
              component={FeedEventDetailsPage}
              exact
            />
            <PrivateRoute
              path="/viestit/:conversationId"
              component={MessagesPage}
              exact
            />
            <PrivateRoute path="/profiili" component={ProfilePage} exact />
            <PrivateRoute path="/kalenteri" component={CalendarPage} exact />
            <PrivateRoute
              path="/kalenteri/:eventId"
              component={CalendarEventDetailsPage}
            />
            <PrivateRoute path="/koti/:id" component={NursingHomePage} exact />
            <PrivateRoute path="/käyttöehdot" component={TermsPage} exact />
            <PrivateRoute
              path="/tietosuojakäytäntö"
              component={PrivacyPolicyPage}
              exact
            />
            <Redirect to={"/"} />
          </IonRouterOutlet>
        </IonPage>
      </>
    );
  };

  const getContent = () => {
    return (
      <IonReactRouter ref={routerRef}>
        <WindowResize />
      </IonReactRouter>
    );
  };

  return (
    <Suspense fallback={null}>
      <IonApp>
        <UserContextProvider>
          <UIContextProvider>
            <CalendarContextProvider>
              <MessagesContextProvider>{getContent()}</MessagesContextProvider>
            </CalendarContextProvider>
          </UIContextProvider>
        </UserContextProvider>
      </IonApp>
    </Suspense>
  );
};

export default App;
