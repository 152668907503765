import {
  ActionType,
  Conversation,
  Message,
  MessagesActionTypes,
} from "./MessagesActionTypes";
import { MessagesUtil } from "util/MessagesUtil";

export interface MessagesAppState {
  conversations: Conversation[] | null; // Array containing all active conversations
  hasUnreadMessages: boolean; // True when there are unread messages
  messages: Message[]; // Array containing all messages of visible conversation
}

export const initialState: MessagesAppState = {
  conversations: null,
  hasUnreadMessages: false,
  messages: [],
};

export const messagesReducer = (
  state: MessagesAppState,
  action: MessagesActionTypes
) => {
  switch (action.type) {
    case ActionType.GetConversationsResponse: {
      const hasUnreadMessages = MessagesUtil.hasUnreadMessages(
        action.conversations
      );
      return { ...state, ...action, hasUnreadMessages };
    }
    case ActionType.ClearMessages: {
      return { ...state, messages: [] };
    }
    case ActionType.GetMessagesResponse: {
      return { ...state, messages: [...action.messages, ...state.messages] };
    }
    case ActionType.NewMessage: {
      let newMessages = [...state.messages];
      if (action.id) {
        const index = state.messages.findIndex((m: Message) => {
          return m.id === action.id;
        });
        console.warn("MESSAGE IND " + index);
        if (index !== -1) {
          newMessages[index] = action.message;
        }
      } else {
        newMessages = newMessages.concat(action.message);
      }

      return { ...state, messages: newMessages };
    }
    case ActionType.MarkAsReadResponse: {
      if (state.conversations) {
        const index = state.conversations.findIndex((value: Conversation) => {
          return value.id.toString() === action.conversationId;
        });
        if (index !== -1) {
          state.conversations[index].unreadCount = action.unreadCount;
          const hasUnreadMessages = MessagesUtil.hasUnreadMessages(
            state.conversations
          );
          return { ...state, hasUnreadMessages };
        }
      }
      return { ...state };
    }
  }
};
