import React, { useContext, useEffect } from "react";
import {
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonText,
  IonToggle,
} from "@ionic/react";

import "./ProfilePage.css";

import { RouteComponentProps, useLocation } from "react-router";
import { withUserService, WithUserServiceProps } from "service";
import ProfilePageHeader from "./ProfilePageHeader";
import { UserContext } from "providers/User";
import { EMAIL_NOTIFICATIONS, UserUtil } from "util/UserUtil";
import { SettingItem } from "providers/User/UserActionTypes";
import { UIActions } from "providers/UI/UIActions";
import { MainTab } from "providers/UI";
import { UIContext } from "providers/UI/UIAppState";
import { useTranslation } from "react-i18next";

const ProfilePage = (props: RouteComponentProps & WithUserServiceProps) => {
  const { state } = useContext(UserContext);
  const { state: uiState, dispatch } = useContext(UIContext);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/profiili") {
      props.userService
        .fetchProfile()
        .then(() => {})
        .catch(() => {});
    }
  }, [location]);

  const onClickSendMessage = (e: any) => {
    e.preventDefault();
    dispatch(UIActions.changeMainTab(MainTab.MessagesTab));
    props.history.replace("/etusivu");
  };

  const onEmailNotificationsToggled = (e: any) => {
    const settings = UserUtil.setEmailNotificationsEnabled(
      e.detail.checked,
      state.settings
    );
    props.userService
      .saveSettings({ settings })
      .then(() => {})
      .catch(() => {
        // TODO Need handling
      });
  };

  function renderLanguageSection() {
    return null;
    return (
      <>
        <div className="marginTop30px" />
        <IonItem className="body2Bold">
          <IonText color="grey">{t("language")}</IonText>
        </IonItem>
        <div className="pageDivider" />
        <IonRow
          className="profilePageRow clickable"
          onClick={() => {
            // TODO Need handling
          }}
        >
          <IonCol className="ion-no-padding centered">
            <IonItem className="body1">
              <IonText color="grey">{t("finnish")}</IonText>
            </IonItem>
          </IonCol>
          <IonCol size="auto" className="centered">
            <IonImg
              className="profilePageChevron"
              color="red"
              src={"assets/ic_chevron_red.svg"}
            />
          </IonCol>
        </IonRow>
        <div className="pageDivider" />
      </>
    );
  }

  function renderSettings() {
    return state.settings.map((item: SettingItem) => {
      if (item.name === EMAIL_NOTIFICATIONS) {
        return (
          <>
            <div className="marginTop30px" />
            <IonItem className="body2Bold">
              <IonText color="grey">{t("notifications")}</IonText>
            </IonItem>
            <div className="pageDivider" />
            <IonRow className="profilePageRow">
              <IonCol className="ion-no-padding centered">
                <IonItem className="body1">
                  <IonText color="grey">{t("emailNotification")}</IonText>
                </IonItem>
              </IonCol>
              <IonCol size="auto" className="centered">
                <IonToggle
                  mode="ios"
                  className="profilePageToggle"
                  color="theme"
                  checked={UserUtil.emailNotificationsChecked(state.settings)}
                  onIonChange={onEmailNotificationsToggled}
                />
              </IonCol>
            </IonRow>
            <div className="pageDivider" />
            <div className="marginTop15px" />
            <IonItem className="body2 noPadding ion-margin-horizontal">
              <IonText color="grey">{t("emailNotificationInfo")}</IonText>
            </IonItem>
          </>
        );
      }
      return null;
    });
  }

  function renderTerms() {
    return (
      <>
        <div className="marginTop30px" />
        <IonItem className="body2Bold">
          <IonText color="grey">{t("termsAndConditions")}</IonText>
        </IonItem>
        <div className="pageDivider" />
        <IonRow
          className="profilePageRow ion-activatable ripple-parent pointer"
          onClick={() => {
            props.history.push("/käyttöehdot");
          }}
        >
          <IonCol className="ion-no-padding centered">
            <IonItem className="body1">
              <IonText color="theme">{t("readTermsAndConditions")}</IonText>
            </IonItem>
          </IonCol>
          <IonRippleEffect style={{ zIndex: 9999 }} />
        </IonRow>
        <div className="pageDivider" />
        <IonRow
          className="profilePageRow ion-activatable ripple-parent pointer"
          onClick={() => {
            props.history.push("/tietosuojakäytäntö");
          }}
        >
          <IonCol className="ion-no-padding centered">
            <IonItem className="body1">
              <IonText color="theme">{t("readPrivacyPolicy")}</IonText>
            </IonItem>
          </IonCol>
          <IonRippleEffect style={{ zIndex: 9999 }} />
        </IonRow>
        <div className="pageDivider" />
      </>
    );
  }

  return (
    <IonPage className="pageLightGrey">
      <ProfilePageHeader />
      <IonContent className="contentMaxWidth">
        <IonList className="ion-no-padding" lines="none">
          {!uiState.isSplitPaneVisible && (
            <div>
              <IonItem className="centered pageHeader">
                <IonLabel color="theme" className="heading1SemiBold">
                  {t("profileAndSettings").toUpperCase()}
                </IonLabel>
              </IonItem>
              <div className="pageHeaderDivider" />
            </div>
          )}
          <IonList className="ion-padding" lines="none">
            <IonItem className="profilePageSectionTitle body2Bold">
              <IonText color="grey">{t("contactInformation")}</IonText>
            </IonItem>
            <div className="pageDivider" />
            <div className="space" />
            <IonItem className="body3 opacity50 noPadding ion-margin-horizontal">
              <IonText color="grey">{t("name")}</IonText>
            </IonItem>
            <IonItem className="body1 noPadding ion-margin-horizontal">
              <IonText color="grey">
                {state.firstName + " " + state.lastName}
              </IonText>
            </IonItem>
            <div className="marginTop15px" />
            <IonItem className="body3 opacity50 noPadding ion-margin-horizontal">
              <IonText color="grey">{t("phoneNumber")}</IonText>
            </IonItem>
            <IonItem className="body1 noPadding ion-margin-horizontal">
              <IonText color="grey">{state.phoneNumber}</IonText>
            </IonItem>
            <div className="marginTop15px" />
            <IonItem className="body3 opacity50 noPadding ion-margin-horizontal">
              <IonText color="grey">{t("email")}</IonText>
            </IonItem>
            <IonItem className="body1 noPadding ion-margin-horizontal">
              <IonText color="grey">{state.email}</IonText>
            </IonItem>
            <div className="marginTop15px" />
            <div className="pageDivider" />
            <div className="marginTop15px" />
            <IonItem className="body2 noPadding ion-margin-horizontal">
              <IonText color="grey">
                {t("errorInContactInformation")}{" "}
                <span className="profilePageSpan" onClick={onClickSendMessage}>
                  {t("sendUsMessage")}
                </span>{" "}
                {t("weFixInformation")}
              </IonText>
            </IonItem>
            {renderLanguageSection()}
            {renderSettings()}
            {renderTerms()}
          </IonList>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withUserService(ProfilePage);
