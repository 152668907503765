type Branding = {
  pageTitle: string,
  logo: string,
  favicon: string,
  themeColor: string,
  shortName: string,
}

const esperiBranding = {
  pageTitle: 'Esperi Omaisviestintä',
  logo: 'assets/logo_esperi.svg',
  favicon: '/assets/icon/favicon.png',
  themeColor: "#ee1b25",
  shortName: "Esperi"
}

const sagaBranding = {
  pageTitle: 'Saga Omaisviestintä',
  logo: 'assets/logo_saga.svg',
  favicon: '/assets/icon/favicon_saga.png',
  themeColor: "#ee1b25",
  shortName: "Saga"
}

const elpyBranding = {
  pageTitle: 'Elpy Omaisviestintä',
  logo: 'assets/logo_elpy.png',
  favicon: '/assets/icon/favicon_elpy.png',
  themeColor: "#58AF59",
  shortName: "Elpy"
}

const tuikkuBranding = {
  pageTitle: 'Tuikku Omaisviestintä',
  logo: 'assets/logo_tuikku.png',
  favicon: '/assets/icon/favicon_tuikku.png',
  themeColor: "#00AEEF",
  shortName: "Tuikku"
}

const brandingMap: { [key: string]: Branding; } = {
  // esperi
  'oma.esperi.fi': esperiBranding,
  'd11sllnwkpyawy.cloudfront.net': esperiBranding, // prod
  'dccpuwaqr2lyx.cloudfront.net': esperiBranding, // dev
  // saga
  'oma.sagacare.fi': sagaBranding,
  'doagfycxbo5eu.cloudfront.net': sagaBranding, // prod
  'd2mjr8o6t9a6c.cloudfront.net': sagaBranding, // dev
  // elpy
  'd2bqo539o4yzpd.cloudfront.net': tuikkuBranding, // dev
  'd7kyer01k24nq.cloudfront.net': tuikkuBranding, // prod
  'elpy.omatuikku.fi': elpyBranding,
  'elpy.omatuikku.com': elpyBranding,
  'localhost': elpyBranding,
  'oma.omatuikku.fi': tuikkuBranding,
  'oma.omatuikku.com': tuikkuBranding,
}

const getBranding = (hostname: string): Branding => {
  return brandingMap[hostname] || esperiBranding;
}

export const getPageTitle = (brand?: string) => getBranding(brand || window.location.hostname).pageTitle;

export const getShortName = (brand?: string) => getBranding(brand || window.location.hostname).shortName;

export const getLogo = (brand?: string) => getBranding(brand || window.location.hostname).logo;

export const getFavicon = (brand?: string) => {
  const branding = getBranding(brand || window.location.hostname)
  return `${window.location.origin}${branding.favicon}`;
}

export const getBrandStrings = (brand?: string) => getBranding(brand || window.location.hostname);

export const getThemeColor = (brand?: string) => getBranding(brand || window.location.hostname).themeColor;

export const getManifest = (brand?: string) => {
  const branding = getBranding(brand || window.location.hostname);
  const manifest = {
    "short_name": branding.pageTitle,
    "name": branding.pageTitle,
    "icons": [
      {
        "src": `${window.location.origin}${branding.favicon}`,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": `${window.location.origin}${branding.favicon}`,
        "type": "image/png",
        "sizes": "512x512",
        "purpose": "maskable"
      }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#ffffff",
    "background_color": "#ffffff"
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  return URL.createObjectURL(blob);
};