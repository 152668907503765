import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Participant } from "providers/Messages/MessagesActionTypes";
import "../MainPage.css";

interface MessageTopicProps {
  title: string;
  participants: Participant[];
}

const MessageTopic: React.FC<MessageTopicProps> = (props) => {
  const { t } = useTranslation();

  function participantList() {
    let text = "";
    props.participants.forEach((participant: Participant, i: number) => {
      const name = participant.firstName || t("you");
      text += name + (i + 1 < props.participants.length ? ", " : "");
    });
    return text;
  }

  return (
    <div className="messagesHeader contentMaxWidth">
      <p className="body2Bold centered grey messagesTitle">{props.title}</p>
      <p className="noPadding smallText centered grey messagesParticipants">
        {participantList()}
      </p>
      <IonItem className="divider" />
    </div>
  );
};

export default MessageTopic;
