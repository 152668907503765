import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formatter } from "util/Formatter";
import { Message } from "providers/Messages/MessagesActionTypes";
import "./Messages.css";
import { withMessagesService, WithMessagesServiceProps } from "service";
import { useInView } from "react-intersection-observer";

interface CaregiverMessageCellProps extends WithMessagesServiceProps {
  customerId: string;
  message: Message;
}

const CaregiverMessageCell = (props: CaregiverMessageCellProps) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  useEffect(() => {
    if (inView) {
      // Mark as read
      if (!props.message.read) {
        props.messagesService.markAsRead(props.customerId, props.message);
      }
    }
  }, [inView]);

  const { t } = useTranslation();

  return (
    <div ref={ref} className="caregiverMessageCell" slot="start">
      <div className="caregiverMessageCellTop">
        <p className="caregiverMessageSender ion-no-margin">
          {props.message.author?.firstName}
        </p>
        <div style={{ flex: 1 }} />
        <p className="caregiverMessageDate ion-no-margin">
          {Formatter.getMessageSendTime(props.message.created, t)}
        </p>
      </div>
      <p className="body2 caregiverMessage charCoal ion-no-margin">
        {props.message.message}
      </p>
    </div>
  );
};

export default withMessagesService(CaregiverMessageCell);
