import React from "react";
import { IonRow, IonCol } from "@ionic/react";

import "./Messages.css";

interface MessageDividerProps {
  title: string;
}

const MessageDivider: React.FC<MessageDividerProps> = (props) => {
  return (
    <IonRow className="messageDivider">
      <IonCol className="ion-no-padding ion-no-margin messageDividerLine" />
      <IonCol size="auto">
        <p className="body3Bold messageDividerTitle">{props.title}</p>
      </IonCol>
      <IonCol className="ion-no-padding ion-no-margin messageDividerLine" />
    </IonRow>
  );
};

export default MessageDivider;
