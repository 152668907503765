import React from "react";
import { IonItem, IonText } from "@ionic/react";

interface PageSectionProps {
  title: string;
}

const PageSection: React.FC<PageSectionProps> = (props) => {
  return (
    <IonItem className="ion-no-padding">
      <IonText color="grey" className="body1Bold capitalizeFirstLetter">
        {props.title}
      </IonText>
    </IonItem>
  );
};

export default PageSection;
