import React from "react";
import {
  IonList,
  IonText,
  IonCol,
  IonRow,
  IonItem,
  IonRippleEffect,
} from "@ionic/react";

import "./Conversations.css";
import moment from "moment";
import { Conversation, Customer } from "providers/Messages/MessagesActionTypes";
import { Formatter } from "util/Formatter";
import { useTranslation } from "react-i18next";

interface ConversationCellProps {
  conversation: Conversation;
  onClick: () => void;
}

const ConversationCell = (props: ConversationCellProps) => {
  const { t } = useTranslation();

  function getTitle(): string {
    let text = "";
    props.conversation.customers.forEach((participant: Customer) => {
      text += participant.firstName + ", ";
    });
    text += props.conversation.location;
    return text;
  }

  function getSecondLine(): string {
    const lastMessage = props.conversation.lastMessage;
    if (lastMessage) {
      let text = Formatter.getMessageSendDate(moment(lastMessage.created), t);
      if (lastMessage.author) {
        text += ", " + lastMessage.author.firstName;
      }
      text += ": " + lastMessage.message;
      return text;
    }
    return "";
  }

  return (
    <div className="conversationCell">
      <IonList
        className="ion-no-padding"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        <IonRow className="conversationCellRow">
          <IonCol className="ion-no-padding">
            <IonItem className="noPadding">
              <IonText className="body1Bold">{getTitle()}</IonText>
            </IonItem>
            <IonItem className="noPadding">
              <IonText className="body3 conversationCellSecondLine">
                {getSecondLine()}
              </IonText>
            </IonItem>
          </IonCol>
          <IonCol
            size="auto"
            className="centered ion-no-padding"
            hidden={props.conversation.unreadCount === 0}
          >
            <p className="body2Bold conversationCellBadge">
              {props.conversation.unreadCount}
            </p>
          </IonCol>
        </IonRow>

        <IonItem className="divider" />
      </IonList>
      <IonRippleEffect style={{ zIndex: 9999 }} />
    </div>
  );
};

export default ConversationCell;
