import React, { createContext, useReducer, Dispatch } from "react";
import {
  initialState,
  MessagesAppState,
  messagesReducer,
} from "./MessagesReducer";
import { MessagesActionTypes } from "./MessagesActionTypes";
import { logger } from "../index";

interface MessagesContextProps {
  state: MessagesAppState;
  dispatch: Dispatch<MessagesActionTypes>;
}

let MessagesContext = createContext({} as MessagesContextProps);
const loggerReducer = logger(messagesReducer, "Messages");

function MessagesContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <MessagesContext.Provider value={value}>
      {props.children}
    </MessagesContext.Provider>
  );
}

export { MessagesContext, MessagesContextProvider };
