import React, { useRef, useState } from "react";
import { IonContent, IonImg, IonItem, IonPage } from "@ionic/react";

import "./FeedEventDetailsPage.css";

import PageHeader from "components/PageHeader";
import { RouteComponentProps } from "react-router";
import { FeedEventItemReaction, FeedEventItemType } from "service/FeedService";

const FeedEventDetailsPage = (props: RouteComponentProps) => {
  // @ts-ignore
  const event = props.location.state?.event;
  // @ts-ignore
  const feedService = props.location.state?.feedService;
  const videoRef = useRef<HTMLVideoElement>(null);

  function getImage(): string {
    return event?.headerImage?.url || "";
  }

  function getVideo() {
    return event?.headerVideo?.url || "";
  }

  function getContent(): string {
    return event?.content || "";
  }

  function getType(): string {
    return event?.itemType || "";
  }

  const image = getImage();
  const videoUrl = getVideo();

  const [isLiked, setIsLiked] = useState(
    event?.reactions?.find(
      (value: any) => value === FeedEventItemReaction.Like
    ) !== undefined
  );

  function toggleLike() {
    setIsLiked(!isLiked);
    feedService
      .likePost(event.id, !isLiked)
      .then((response: any) => {
        if (response?.data) {
          event.reactions = response.data.reactions;
        }
      })
      .catch(() => {
        setIsLiked(isLiked);
      });
  }

  return (
    <IonPage className="pageLightGrey">
      <PageHeader title="" />
      <IonContent>
        <IonImg
          className="contentMaxWidth feedDetailsImage"
          hidden={!!videoUrl || image === ""}
          src={image}
        />
        {videoUrl && (
          <video
            ref={videoRef}
            src={`${videoUrl}#t=0.1`}
            controls
            preload="metadata"
            autoPlay
          ></video>
        )}
        <IonItem
          hidden={getType() !== FeedEventItemType.Customer}
          className="contentMaxWidth ion-no-padding noPadding"
        >
          <IonImg
            className="pointer feedCellLike"
            src={isLiked ? "assets/ic_heart_filled.svg" : "assets/ic_heart.svg"}
            onClick={(e) => {
              e.stopPropagation();
              toggleLike();
            }}
          />
          {image && (
            <a
              className="pointer feedEventDetailsDownload"
              href={videoUrl ? videoUrl : image}
              download
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IonImg src={"assets/ic_download.svg"} />
            </a>
          )}
        </IonItem>
        <p
          className="ion-padding-horizontal ion-margin-vertical contentMaxWidth"
          dangerouslySetInnerHTML={{ __html: getContent() }}
        />
      </IonContent>
    </IonPage>
  );
};

export default FeedEventDetailsPage;
