import React, { useState } from "react";
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonInput,
  IonList,
  IonText,
} from "@ionic/react";

import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import "./EsperiTextField.css";
import { MIN_PASSWORD_LENGTH } from "util/Constants";
import { useTranslation } from "react-i18next";

interface PasswordProps {
  title: string;
  password: string;
  onPasswordChanged: (password: string) => void;
  error: string;
  hasIcon: boolean;
  showPasswordLength: boolean;
  onPressEnter: (e: any) => void | null;
}

const EsperiPasswordTextField = (props: PasswordProps) => {
  const { t } = useTranslation();

  function icon() {
    return showPassword ? eyeOutline : eyeOffOutline;
  }
  function type() {
    return showPassword ? "password" : "text";
  }
  function text() {
    return showPassword ? t("show") : t("hide");
  }

  const [showPassword, setShowPassword] = useState(true);

  return (
    <IonList>
      <IonItem className="noPadding">
        <IonLabel className="textGrayLeftInfoText" color="grey">
          {props.title}
        </IonLabel>
        <IonItem
          className="noPadding ion-no-margin"
          detail={false}
          button
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          slot="end"
        >
          <IonIcon icon={icon()} color="theme" />
          <IonItem className="iconSpacer" />
          <IonLabel className="textRedRightInfoText" color="theme">
            {text()}
          </IonLabel>
        </IonItem>
      </IonItem>
      <IonItem className="inputBackground" lines="none">
        <IonIcon icon={"/assets/ic_lock.svg"} hidden={!props.hasIcon} />
        <IonItem style={{ width: 10 }} />
        <IonInput
          className="grey"
          type={type()}
          value={props.password}
          onIonChange={(e) =>
            props.onPasswordChanged((e.target as HTMLInputElement).value)
          }
          onKeyUp={(e) => {
            if (e.nativeEvent.key === "Enter" && props.onPressEnter !== null) {
              props.onPressEnter(e);
            }
          }}
        />
      </IonItem>
      <IonItem className="inputInfo" hidden={!props.error}>
        <IonIcon icon={"/assets/ic_alert.svg"} />
        <IonText className="smallText inputAlert" color="red">
          {props.error}
        </IonText>
      </IonItem>
      {props.showPasswordLength && !props.error && (
        <IonItem className="inputInfo" lines="none">
          <IonLabel className="textPasswordLength">
            {t("passwordHint", { minLength: MIN_PASSWORD_LENGTH })}
          </IonLabel>
        </IonItem>
      )}
    </IonList>
  );
};

EsperiPasswordTextField.defaultProps = {
  onPressEnter: null,
};

export default EsperiPasswordTextField;
