import React, { useContext } from "react";
import {
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import EsperiAppHeader, {
  HeaderLeftIcon,
} from "../../components/EsperiAppHeader";
import { UIContext } from "providers/UI/UIAppState";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/User";
import "./NursingHomePage.css";
import { SocialIcon } from 'react-social-icons';

const NursingHomePage = (props: RouteComponentProps) => {
  const { state: userState } = useContext(UserContext);
  const { state } = useContext(UIContext);
  const { t } = useTranslation();

  const home = userState.homes.find((home) => {
    return home.id.toString() === props.location.pathname.split("/")[2];
  });

  const image = home?.images[0].url;
  const name = home?.name;
  const contactPersonTitle = home?.contactPerson.title;
  const contactPersonPhone = home?.contactPerson.telephone;
  const contactPersonEmail = home?.contactPerson.email;
  const socialUrl = home?.socialUrl;
  const contactsBody = home?.contactsBody?.replace("h3", "p");

  function getDownloadableMaterials() {
    if (home && home.attachments.length > 0) {
      return (
        <>
          <IonText className="body3Bold grey">
            {t("downloadableMaterials")}
          </IonText>
          <div className="nursingHomeDivider" />
          <div className="marginTop20px" />
          {home.attachments.map((attachment) => {
            return (
              <div
                className="attachmentItem"
                key={attachment.url}
                onClick={() => {
                  window.open(attachment.url, "_blank")?.focus();
                }}
              >
                <IonText className="attachmentText body2Bold theme">
                  {attachment.name}
                </IonText>
              </div>
            );
          })}
        </>
      );
    }
  }

  return (
    <IonPage className="pageLightGrey calendarEventDetails">
      <EsperiAppHeader
        hideLeftIcon={state.isSplitPaneVisible}
        hideEsperiLogo={state.isSplitPaneVisible}
        hideRightIcon={false}
        leftIconType={HeaderLeftIcon.Menu}
      />
      <IonContent className="contentMaxWidth">
        {!state.isSplitPaneVisible && (
          <div>
            <IonItem className="centered pageHeader" lines="none">
              <IonLabel color="theme" className="heading1SemiBold">
                {t("nursingHomeDetails").toUpperCase()}
              </IonLabel>
            </IonItem>
            <div className="pageHeaderDivider" />
          </div>
        )}
        <div className="nursingHomeContent">
          <IonImg
            style={{
              marginTop: state.isSplitPaneVisible ? 16 : 0,
              marginLeft: state.isSplitPaneVisible ? 6 : 0,
            }}
            hidden={!image}
            className="nursingHomeImage"
            src={image}
          />
          <div className="nursingHomeInfo">
            <IonText className="body1Bold grey nursingHomeContentText">
              {name}
            </IonText>
            {socialUrl && <SocialIcon url={socialUrl} />}
            <div className="marginTop20px" />
            <IonText className="body3Bold grey">
              {t("contactPersonnel")}
            </IonText>
            <div className="nursingHomeDivider" />
            <div className="marginTop20px" />
            <IonText
              hidden={!contactPersonTitle}
              className="body2Bold grey nursingHomeContentText"
            >
              {contactPersonTitle}
            </IonText>
            <IonText className="body2 grey">{home?.contactPerson.name}</IonText>
            <a
              hidden={!contactPersonPhone}
              className="body2 theme"
              href={"tel:" + contactPersonPhone}
            >
              {contactPersonPhone}
            </a>
            <a
              hidden={!contactPersonEmail}
              className="body2 theme"
              href={"mailto:" + contactPersonEmail}
            >
              {contactPersonEmail}
            </a>
            {contactsBody && (
              <p
                className="body2 grey"
                dangerouslySetInnerHTML={{ __html: contactsBody }}
              />
            )}
            {!contactsBody && <div className="marginTop20px" />}
            {getDownloadableMaterials()}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NursingHomePage;
