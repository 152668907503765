import React from "react";
import { IonList, IonItem, IonImg } from "@ionic/react";
import "./EsperiHeader.css";
import { getLogo } from 'util/Branding';

const EsperiBigHeader = () => {
  return (
    <IonList className="ion-no-border" lines="none">
      <IonItem className="bigHeaderTopSpacer" />
      <IonImg className="centerBigImage" src={getLogo()} />
      <IonItem className="bigHeaderBottomSpacer" />
    </IonList>
  );
};

export default EsperiBigHeader;
