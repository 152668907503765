export const MIN_PASSWORD_LENGTH = 10;

export enum LocalStorageKeys {
  email = "ec-frontend-customer-email",
  IdToken = "ec-frontend-customer-IdToken",
  RefreshToken = "ec-frontend-customer-RefreshToken",
  AccessToken = "ec-frontend-customer-AccessToken",
  ClientId = "ec-frontend-customer-ClientId",
  UserPoolId = "ec-frontend-customer-UserPoolId",
  SeenOnboarding = "ec-frontend-customer-SeenOnboarding",
}

const HTTP_API_URL_DEV =
  "https://grkysxxt3e.execute-api.eu-west-1.amazonaws.com/dev";
const HTTP_API_KEY_DEV = "v9VIBrKwEkx8EiWOU4XxVJR7uwP0ZM2ogjaKnGc0";

const HTTP_API_URL_PROD =
  "https://a7qiguuzoj.execute-api.eu-west-1.amazonaws.com/prod";
const HTTP_API_KEY_PROD = "4nvTmglDWg1Vho5xa08oW2z3dT6w1InF9jDIgyjQ";

const HTTP_API_URL_TUIKKU_DEV =
  "https://42i2e31j9c.execute-api.eu-north-1.amazonaws.com/dev";
const HTTP_API_KEY_TUIKKU_DEV = "EK7ZCfbzZH3VdQOAXoPicaaBJXNbAZ7PYpjnSMja";

const HTTP_API_URL_TUIKKU_PROD =
  "https://lbuxvp69oh.execute-api.eu-north-1.amazonaws.com/prod";
const HTTP_API_KEY_TUIKKU_PROD = "tRix4tBh4h60BLcSuCnBh9ytbJMpIVcr6Rgdp1zA";

export const HTTP_API_URL =
  process.env.REACT_APP_STAGE === "prod" ? HTTP_API_URL_PROD :
    process.env.REACT_APP_STAGE === "tuikku-prod" ? HTTP_API_URL_TUIKKU_PROD :
      process.env.REACT_APP_STAGE === "tuikku-dev" ? HTTP_API_URL_TUIKKU_DEV : HTTP_API_URL_DEV;
export const HTTP_API_KEY =
  process.env.REACT_APP_STAGE === "prod" ? HTTP_API_KEY_PROD :
    process.env.REACT_APP_STAGE === "tuikku-prod" ? HTTP_API_KEY_TUIKKU_PROD :
      process.env.REACT_APP_STAGE === "tuikku-dev" ? HTTP_API_KEY_TUIKKU_DEV : HTTP_API_KEY_DEV;
export const HTTP_LOGS_ENABLED = false;
export const STATE_LOGS_ENABLED = process.env.REACT_APP_STAGE?.endsWith("prod");
