import React from "react";
import { IonSpinner } from "@ionic/react";
import "./EsperiSpinner.css";

export enum SpinnerSize {
  Small = "spinnerSmall",
  Default = "spinnerDefault",
  Large = "spinnerLarge",
}

interface EsperiSpinnerProps {
  size: SpinnerSize;
  hidden: boolean;
}

const EsperiSpinner = (props: EsperiSpinnerProps) => {
  return (
    <div className="contentMaxWidth">
      <IonSpinner
        {...props}
        color="primary"
        name="crescent"
        className={props.size}
      />
    </div>
  );
};

export default EsperiSpinner;
