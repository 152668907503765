import React from "react";
import { useTranslation } from "react-i18next";
import { Formatter } from "util/Formatter";
import { Message } from "providers/Messages/MessagesActionTypes";
import { withMessagesService, WithMessagesServiceProps } from "service";
import "./Messages.css";

interface OwnMessageCellProps extends WithMessagesServiceProps {
  customerId: string;
  message: Message;
}

const OwnMessageCell = (props: OwnMessageCellProps) => {
  const { t } = useTranslation();

  function getClassName() {
    let className = "ownMessageCell";
    if (props.message.failed) {
      className += " ownMessageCellFailed";
    }
    return className;
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }} slot="end">
        <img
          style={{ opacity: props.message.failed ? 1 : 0 }}
          className="ownMessageAlert"
          src={"/assets/ic_alert.svg"}
        />
        <div className={getClassName()}>
          <p className="body2 charCoal ion-no-margin">
            {props.message.message}
          </p>
          <p className="ownMessageDate charCoal ion-no-margin">
            {Formatter.getMessageSendTime(props.message.created, t)}
          </p>
        </div>
      </div>
      {props.message.failed && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: 90,
            padding: 0,
          }}
        >
          <p
            style={{ margin: 0, paddingTop: 8, paddingBottom: 12 }}
            className="red body3"
          >
            {t("sendMessageError")}
          </p>
          <p
            style={{
              margin: 0,
              paddingLeft: 4,
              paddingTop: 8,
              paddingBottom: 12,
              paddingRight: 12,
            }}
            className="red body3Bold pointer"
            onClick={() => {
              const message = props.message;
              message.failed = false;
              props.messagesService.sendMessage(
                props.customerId,
                message,
                true
              );
            }}
          >
            {t("tryAgain")}
          </p>
        </div>
      )}
    </div>
  );
};

export default withMessagesService(OwnMessageCell);
