import {
  ActionType,
  MessagesActionTypes,
  GetConversationsResponse,
  MessagesResponse,
  Message,
} from "./MessagesActionTypes";

export class MessagesActions {
  static clearMessages(): MessagesActionTypes {
    return {
      type: ActionType.ClearMessages,
    };
  }

  static onGetConversationsResponse(
    response: GetConversationsResponse
  ): MessagesActionTypes {
    return {
      type: ActionType.GetConversationsResponse,
      conversations: response.conversations,
    };
  }

  static onMessagesResponse(messages: Message[]): MessagesActionTypes {
    return {
      type: ActionType.GetMessagesResponse,
      messages,
    };
  }

  static onNewMessage(
    message: Message,
    id: string | null
  ): MessagesActionTypes {
    return {
      type: ActionType.NewMessage,
      message,
      id,
    };
  }

  static onMarkAsReadResponse(
    conversationId: string,
    unreadCount: number
  ): MessagesActionTypes {
    return {
      type: ActionType.MarkAsReadResponse,
      conversationId,
      unreadCount,
    };
  }
}
