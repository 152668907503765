export enum ActionType {
  GetConversationsResponse = "GetConversationsResponse",
  ClearMessages = "ClearMessages",
  GetMessagesResponse = "GetMessagesResponse",
  NewMessage = "NewMessage",
  MarkAsReadResponse = "MarkAsReadResponse",
}

export interface GetConversationsResponse {
  type: ActionType.GetConversationsResponse;
  conversations: Conversation[];
}

export interface ClearMessages {
  type: ActionType.ClearMessages;
}

export interface GetMessagesResponse {
  type: ActionType.GetMessagesResponse;
  messages: Message[];
}

export interface NewMessage {
  type: ActionType.NewMessage;
  message: Message;
  id: string | null;
}

export interface MarkAsReadResponse {
  type: ActionType.MarkAsReadResponse;
  conversationId: string;
  unreadCount: number;
}

export type MessagesActionTypes =
  | GetConversationsResponse
  | ClearMessages
  | GetMessagesResponse
  | NewMessage
  | MarkAsReadResponse;

/*
 MODEL
 */
export interface Conversation {
  id: number;
  location: string;
  customers: Customer[];
  participants: Participant[];
  lastMessage: Message | null;
  unreadCount: number;
}

export interface Customer {
  firstName: string;
  lastName: string;
}

export interface Message {
  id: string;
  author: Participant | null;
  message: string;
  created: string;
  read: boolean;
  failed: boolean | undefined | null;
}

export interface Participant {
  firstName: string;
  lastName: string;
}

export interface MessagesResponse {
  title: string;
  participants: Participant[];
  messages: Message[];
  nextPage: string;
  unreadCount: number;
  sendingEnabled: boolean;
}
