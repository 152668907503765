import React, { createContext, useReducer, Dispatch } from "react";
import {
  initialState,
  CalendarAppState,
  calendarReducer,
} from "./CalendarReducer";
import { CalendarActionTypes } from "./CalendarActionTypes";
import { logger } from "../index";

interface CalendarContextProps {
  state: CalendarAppState;
  dispatch: Dispatch<CalendarActionTypes>;
}

let CalendarContext = createContext({} as CalendarContextProps);
const loggerReducer = logger(calendarReducer, "CalendarPage");

function CalendarContextProvider(props: any) {
  let [state, dispatch] = useReducer(loggerReducer, initialState);
  let value = { state, dispatch };

  return (
    <CalendarContext.Provider value={value}>
      {props.children}
    </CalendarContext.Provider>
  );
}

export { CalendarContext, CalendarContextProvider };
