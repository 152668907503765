import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IonItem, IonIcon, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { withMessagesService, WithMessagesServiceProps } from "service";
import { Message } from "providers/Messages/MessagesActionTypes";
import moment from "moment";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";
import "./Messages.css";

interface NewMessageProps extends WithMessagesServiceProps {
  conversationId: string;
  onMessageSent: () => void;
}

const NewMessage = (props: NewMessageProps) => {
  const [text, setText] = useState("");
  const [isSending, setSending] = useState(false);
  const { t } = useTranslation();

  function sendMessage() {
    if (!isSending) {
      setSending(true);
      const messageId = uuidv4();
      const message = {
        id: messageId,
        created: moment().toISOString(),
        author: null,
        message: text,
        read: false,
        failed: false,
      };
      props.messagesService
        .sendMessage(props.conversationId, message)
        .then(() => {
          setSending(false);
        })
        .catch(() => {
          props.onMessageSent();
          setSending(false);
        });
      props.onMessageSent();
      setText("");
    }
  }

  return (
    <IonItem color="lightGrey" lines="none" className="contentMaxWidth">
      <IonButton hidden fill="clear" className="newMessageCamera">
        <IonIcon
          className="newMessageCameraIcon"
          icon={"/assets/ic_camera.svg"}
        />
      </IonButton>
      <TextareaAutosize
        id="newMessageInput"
        className="newMessageInput"
        value={text}
        placeholder={t("newMessagePlaceholder")}
        maxRows={10}
        inputMode="text"
        onChange={(e) => setText((e.target as HTMLInputElement).value)}
      />
      <IonIcon
        hidden={text.length === 0}
        className="newMessageSendIcon"
        icon={"/assets/ic_message_send.svg"}
        onClick={() => {
          sendMessage();
        }}
      />
    </IonItem>
  );
};

export default withMessagesService(NewMessage);
